import { useContext, useEffect, useState } from "react";
import { themeContext } from "../../context/theme";
import { CardOne } from "../../components/cards/cardOne";
import { YouWon } from "../../components/svg/youWon";
import { Tie } from "../../components/svg/tie";
import { DonutProgress } from "./donutProgress";
import styles from "./playerResult.module.css";
export const PlayerResult = ({ player, index, game, winner }) => {
      const { theme } = useContext(themeContext);
      const [score, setScore] = useState(0);
      const statsLoop = () => {
            if (score + 20 > player.stats.score) {
                  setScore(player.stats.score);
            } else {
                  setScore((previous) => {
                        return previous + 20;
                  });
            }
      };

      useEffect(() => {
            requestAnimationFrame(statsLoop);
      }, [score]);
      return (
            <div className={styles["player"]}>
                  {game.currentGameMode === "singlePlayerMode" ? (
                        game.singlePlayerMode.challengeWon === "yes" ? (
                              <YouWon></YouWon>
                        ) : (
                              //   <YouLost></YouLost>

                              <div
                                    className={
                                          styles["donuts-progress-container"]
                                    }
                              >
                                    <div className={styles["donut-container"]}>
                                          <DonutProgress
                                                customClass={
                                                      styles["donut-progress"]
                                                }
                                                challengeStats={{
                                                      howCloseToTarget:
                                                            game
                                                                  .singlePlayerMode
                                                                  .rating,
                                                }}
                                          ></DonutProgress>
                                          <div>rating</div>
                                    </div>
                                    <div
                                          style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "3px",
                                                "align-items": "center",
                                          }}
                                    >
                                          <DonutProgress
                                                customClass={
                                                      styles["donut-progress"]
                                                }
                                                challengeStats={{
                                                      howCloseToTarget:
                                                            game
                                                                  .singlePlayerMode
                                                                  .bestRating,
                                                }}
                                          ></DonutProgress>
                                          <div>best</div>
                                    </div>
                              </div>
                        )
                  ) : null}

                  {game.currentGameMode === "splitScreenMode" &&
                  game.splitScreenMode.winners.length === 1 &&
                  winner === "yes" ? (
                        <YouWon></YouWon>
                  ) : null}
                  {game.currentGameMode === "splitScreenMode" &&
                  game.splitScreenMode.winners.length > 1 &&
                  winner === "yes" ? (
                        <Tie></Tie>
                  ) : null}

                  <CardOne
                        attributes={{
                              className:
                                    styles["player-result"] +
                                    " " +
                                    styles["player-result-" + theme],
                        }}
                  >
                        <div className={styles["player-number"]}>
                              {index + 1}. PLAYER-{player.number}
                        </div>
                        <div
                              className={
                                    styles["player-score"] +
                                    " " +
                                    styles["player-stat"]
                              }
                        >
                              <div>Score:</div>
                              <div>{score}</div>
                        </div>
                        <div className={styles["player-stat"]}>
                              <div>1x shots:</div>
                              <div>{player.stats.singleShots}</div>
                        </div>
                        <div className={styles["player-stat"]}>
                              <div>2x shots:</div>
                              <div>{player.stats.doubleShots}</div>
                        </div>
                        <div className={styles["player-stat"]}>
                              <div>3x shots:</div>
                              <div>{player.stats.tripleShots}</div>
                        </div>
                        <div className={styles["player-stat"]}>
                              <div>4x shots:</div>
                              <div>{player.stats.quadraShots}</div>
                        </div>
                        <div className={styles["player-stat"]}>
                              <div>total shots:</div>
                              <div>
                                    {player.stats.singleShots +
                                          player.stats.doubleShots * 2 +
                                          player.stats.tripleShots * 3 +
                                          player.stats.quadraShots * 4}
                              </div>
                        </div>
                        <div className={styles["player-stat"]}>
                              <div>Time elapsed:</div>
                              <div>{player.time}s</div>
                        </div>
                        <div className={styles["player-stat"]}>
                              <div>life saver left:</div>
                              <div>{player.lifeSaverCount}</div>
                        </div>
                  </CardOne>
            </div>
      );
};
