import styles from "./tooltip.module.css";
import { useState } from "react";

export const ToolTip = ({ children, text, theme }) => {
      const [showToolTip, setShowToolTip] = useState(false);
      return (
            <div
                  style={{ position: "relative", cursor: "pointer" }}
                  onMouseEnter={() => {
                        setShowToolTip(true);
                  }}
                  onMouseLeave={() => {
                        setShowToolTip(false);
                  }}
            >
                  {children}
                  {showToolTip ? (
                        <div
                              className={
                                    styles["nav-item-name"] +
                                    " " +
                                    styles["nav-item-name-" + theme]
                              }
                        >
                              {text}
                        </div>
                  ) : null}
            </div>
      );
};
