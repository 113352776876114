import { Howl } from "howler";
import fallPath from "../assets/sounds/ballTap.wav";
import gameOverPath from "../assets/sounds/game-lost.mp3";
import navigationSoundPath from "../assets/sounds/navigation.m4a";
import clickSoundPath from "../assets/sounds/click.mp3";
import winningSoundPath from "../assets/sounds/winning.mp3";
import blasterSoundPath from "../assets/sounds/blaster.mp3";
import { toast } from "react-toastify";
import deliciousSoundPath from "../assets/sounds/deliciousOne.mp3";
import whipSoundPath from "../assets/sounds/whoosh.wav";
import countingSoundPath from "../assets/sounds/counting.mp3";
import rotateBlockSoundPath from "../assets/sounds/rotateBlockTrimmed.wav";

let x = 0;
let y = null;
export const svgThemes = {
      "theme-0": "rgba(255, 0, 0, 1)",
      "theme-1": "rgba(0, 255, 0, 1)",
      "theme-2": "rgba(128, 0, 128, 1)",
      "theme-3": "rgba(255, 255, 0, 1)",
      "theme-4": "rgba(0, 255, 255, 1)",
};
export const calculateAverage = (
      currentAverageFps,
      framesFinished,
      toBeAddedFps
) => {
      return Math.round(
            (currentAverageFps * framesFinished + toBeAddedFps) /
                  (framesFinished + 1)
      );
};

export const toastOptions = {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
};
export class Game {
      barsState;
      players;
      isGameStarted;
      isGameOver;
      joysticks;
      pause;
      randomTetrominoIndexes;
      tetrominos;
      gameLoopWaitCount;
      renderPlayersUi;
      renderGameResult;
      CoordinatesAndColorsOfTetrominos;
      sounds;
      anyGameStarted;
      keyBoard;
      gameModes;
      renderMenuOverlay;
      speed;
      boardRows;
      boardColumns;
      playersStandings = null;
      timeLimit;
      touch;
      showTouchControls;
      connectedJoysticks;
      fixedFrameRate;
      singlePlayerMode;
      currentGameMode;
      userSavedData;
      time;

      constructor(
            renderPlayersUi,
            renderGameResult,
            renderMenuOverlay,
            setShowGamePadState,
            boardRows,
            boardColumns,
            speed,
            userSavedData
      ) {
            this.time = 0;
            this.userSavedData = userSavedData;
            this.barsState = "idle";
            this.boardRows = boardRows;
            this.boardColumns = boardColumns;
            this.speed = speed;
            this.isGameStarted = false;
            this.isGameOver = null;
            this.fixedFrameRate = {
                  lastRan: -1,
                  timeElapsed: 0,
                  averageFps: 0,
                  currentFps: 0,
                  currentFrameTime: 0,
                  framesCounter: 0,
            };
            this.pause = false;
            this.renderGameResult = renderGameResult;
            this.renderMenuOverlay = renderMenuOverlay;
            this.setShowGamePadState = setShowGamePadState;
            this.CoordinatesAndColorsOfTetrominos = tetrominoes(boardColumns);
            this.randomTetrominoIndexes = this.generateNewTetrominoIndexes(
                  [
                        "l tetromino",
                        "t tetromino",
                        "square tetromino",
                        "line tetromino",
                        "z tetromino",
                  ],
                  500
            );
            this.gameModes = {
                  modeOne: 1,
                  modeTwo: 1,
            };
            this.timeLimit = 300;
            this.players = [
                  new Player(
                        0,
                        this.CoordinatesAndColorsOfTetrominos[
                              this.randomTetrominoIndexes[0]
                        ],
                        this.boardRows,
                        this.boardColumns,
                        this.speed
                  ),
            ];
            this.tetrominos = [
                  {
                        allCoordinates: [
                              [0, 5],
                              [1, 5],
                              [2, 5],
                              [2, 6],
                        ],
                        colorClass: "l-tetromino-active",
                  }, // l tetromino
                  {
                        allCoordinates: [
                              [0, 5],
                              [0, 6],
                              [0, 7],
                              [1, 6],
                        ],
                        colorClass: "t-tetromino-active",
                  }, // t tetromino

                  {
                        allCoordinates: [
                              [0, 5],
                              [0, 6],
                              [1, 5],
                              [1, 6],
                        ],
                        colorClass: "square-tetromino-active",
                  }, // square

                  {
                        allCoordinates: [
                              [0, 5],
                              [0, 6],
                              [0, 7],
                              [0, 8],
                        ],
                        colorClass: "line-tetromino-active",
                  }, // line tetromino

                  {
                        allCoordinates: [
                              [0, 5],
                              [1, 5],
                              [1, 6],
                              [2, 6],
                        ],
                        colorClass: "z-tetromino-active",
                  }, // skew tetromino
            ];
            this.joysticks = [null, null, null, null];
            this.connectedJoysticks = 0;
            this.touch = new Touch(this);
            this.showTouchControls = userSavedData.settings.showTouchControls;
            this.gameLoopWaitCount = 0;
            this.renderPlayersUi = renderPlayersUi;
            this.anyGameStarted = false;
            this.keyBoard = new KeyBoard(this);
            this.onJoyStickConnect();
            this.onJoystickDisconnect();
            this.onKeyboard();
            this.sounds = {
                  navigationSound: new Howl({
                        src: [navigationSoundPath],
                  }),
                  clickSound: new Howl({
                        src: [clickSoundPath],
                  }),
                  countingSound: new Howl({
                        src: [countingSoundPath],
                  }),

                  blasterSound: new Howl({
                        src: [blasterSoundPath],
                  }),

                  fall: new Howl({
                        src: [fallPath],
                  }),
                  rotateBlockSound: new Howl({
                        src: [rotateBlockSoundPath],
                  }),

                  gameOverSound: new Howl({
                        src: [gameOverPath],
                  }),
                  deliciousSound: new Howl({
                        src: [deliciousSoundPath],
                  }),
                  whipSound: new Howl({
                        src: [whipSoundPath],
                  }),

                  winningSound: new Howl({
                        src: [winningSoundPath],
                  }),
            };
            this.singlePlayerMode = {
                  modeOne: "singlePlayer",
                  difficulty: "easy",
                  challenge: null,
                  challenges: [],
                  challengeIndex: 0,
                  patternToMatch: [],
                  challengeWon: null,
                  maxNumberOfPlayers: 1,
                  gameOverPlayers: [],
                  playersStandings: [],
                  rating: 0,
                  bestRating: 0,
            };
            this.splitScreenMode = {
                  modeOne: "singlePlayer",
                  difficulty: "easy",
                  challenge: null,
                  challenges: [],
                  challengeIndex: 0,
                  patternToMatch: [],
                  numberOfPlayersFinished: 0,
                  maxNumberOfPlayers: 2,
                  gameOverPlayers: [],
                  playersStandings: [],
                  winners: [],
                  losers: [],
            };
            this.currentGameMode = "singlePlayerMode";
      }

      createPlayers = (numberOfPlayers) => {
            const players = [];
            for (let i = 0; i < numberOfPlayers; i++) {
                  players.push(
                        new Player(
                              i,
                              this.CoordinatesAndColorsOfTetrominos[
                                    this.randomTetrominoIndexes[0]
                              ],
                              this.boardRows,
                              this.boardColumns,
                              this.speed
                        )
                  );
            }
            this.players = players;
      };

      saveData = function () {
            localStorage.setItem(
                  "userDataOne",
                  JSON.stringify(this.userSavedData)
            );
      };
      gamepadLoop = () => {
            let isTrue = false;
            const joysticks = this.joysticks;
            const gamepads = navigator.getGamepads();
            joysticks.forEach((joystick, joystickIndex) => {
                  //   if (this.gameModes.modeOne === 1 && joystickIndex > 0) {
                  //         return;
                  //   }
                  if (joystick && this.players[joystickIndex]) {
                        if (joystick.throttleCount < 10) {
                              joystick.throttleCount++;
                        }
                        const gamepad = gamepads[joystick.gamepad.index];
                        if (gamepad) {
                              gamepad.buttons.every((button, buttonIndex) => {
                                    if (button.pressed) {
                                          if (
                                                joystick.previousPressedButtonIndex !==
                                                buttonIndex
                                          ) {
                                                finalInput(
                                                      joystick
                                                            .defaultKeyBindings[
                                                            buttonIndex
                                                      ],
                                                      this.players[
                                                            joystickIndex
                                                      ],
                                                      this.sounds,
                                                      gamepad,
                                                      this
                                                );
                                                if (buttonIndex === 9) {
                                                      if (this.isGameStarted) {
                                                            this.renderMenuOverlay(
                                                                  true
                                                            );
                                                            this.pause = true;
                                                            this.sounds.clickSound.play();
                                                      }
                                                }
                                                joystick.previousPressedButtonIndex =
                                                      buttonIndex;
                                                joystick.throttleCount = 0;
                                                isTrue = true;
                                          } else {
                                                if (
                                                      joystick.throttleCount ===
                                                      10
                                                ) {
                                                      finalInput(
                                                            joystick
                                                                  .defaultKeyBindings[
                                                                  buttonIndex
                                                            ],
                                                            this.players[
                                                                  joystickIndex
                                                            ],
                                                            this.sounds,
                                                            null,
                                                            this
                                                      );

                                                      joystick.throttleCount = 0;

                                                      isTrue = true;
                                                }
                                          }
                                          return false;
                                    }
                                    return true;
                              });
                        }
                  }
            });
            return isTrue;
      };
      gameLoop = () => {
            if (this.isGameOver || this.pause) {
                  return;
            }
            let now = performance.now();

            if (this.fixedFrameRate.lastRan === -1) {
                  this.fixedFrameRate.lastRan = now;
            }
            let totalTimeElapsed =
                  now -
                  this.fixedFrameRate.lastRan +
                  this.fixedFrameRate.timeElapsed;

            if (totalTimeElapsed >= 16.7) {
                  //   this.fixedFrameRate.currentFrameTime =
                  //         now - this.fixedFrameRate.lastRan;
                  //   this.fixedFrameRate.currentFps = Math.round(
                  //         1000 / this.fixedFrameRate.currentFrameTime
                  //   );
                  //   this.fixedFrameRate.averageFps = calculateAverage(
                  //         this.fixedFrameRate.averageFps,
                  //         this.fixedFrameRate.framesCounter,
                  //         this.fixedFrameRate.currentFps
                  //   );
                  this.fixedFrameRate.framesCounter++;

                  this.fixedFrameRate.lastRan = now;
                  this.fixedFrameRate.timeElapsed = totalTimeElapsed % 16.7;

                  this.gamepadLoop.bind(this)();

                  // this.gameLoopWaitCount++;
                  //   let shouldReturn = false;

                  this.players.forEach((player, index) => {
                        if (!player.isGameOver && !player.destroyInAction) {
                              player.frameCounter++;

                              if (player.frameCounter === player.currentSpeed) {
                                    let destroyableRows = [];
                                    if (
                                          !finalInput(
                                                "ArrowDown",
                                                player,
                                                null,
                                                null,
                                                this
                                          )
                                    ) {
                                          if (player.currentSpeed === 1) {
                                                player.currentSpeed =
                                                      player.previousSpeed;
                                          }
                                          updateplayerBoardMatrix(player, this);
                                          destroyableRows =
                                                areThereAnydestroyableRows(
                                                      this,
                                                      player
                                                );

                                          if (destroyableRows.length > 0) {
                                                player.numberOfDestroyedRows =
                                                      destroyableRows.length;
                                                destroy(
                                                      destroyableRows,
                                                      this,
                                                      player
                                                );
                                                if (this.joysticks[index]) {
                                                      let duration =
                                                            (destroyableRows.length -
                                                                  1) *
                                                                  200 +
                                                            200;
                                                      this.joysticks[
                                                            index
                                                      ].gamepad.vibrationActuator?.playEffect(
                                                            "dual-rumble",
                                                            {
                                                                  startDelay: 0,
                                                                  duration: duration,
                                                                  weakMagnitude: 1.0,
                                                                  strongMagnitude: 1.0,
                                                            }
                                                      );
                                                }
                                                updateStats(
                                                      player,
                                                      destroyableRows.length,
                                                      this
                                                );
                                          }

                                          if (
                                                player.currentIndexOfrandomTetrominoIndexes +
                                                      1 >=
                                                this.randomTetrominoIndexes
                                                      .length
                                          ) {
                                                player.currentIndexOfrandomTetrominoIndexes = 0;
                                          } else {
                                                player.currentIndexOfrandomTetrominoIndexes++;
                                          }

                                          const newCoordinates =
                                                this
                                                      .CoordinatesAndColorsOfTetrominos[
                                                      this
                                                            .randomTetrominoIndexes[
                                                            player
                                                                  .currentIndexOfrandomTetrominoIndexes
                                                      ]
                                                ];
                                          player.currentTetromino =
                                                newCoordinates;
                                          if (
                                                !finalInput(
                                                      "startingPosition",
                                                      player,
                                                      null,
                                                      null,
                                                      this
                                                )
                                          ) {
                                                player.isGameOver = true;
                                          }
                                    }
                                    this[
                                          this.currentGameMode
                                    ].challenge.conditions(
                                          this,
                                          player,
                                          destroyableRows
                                    );
                                    this.renderPlayersUi({});
                                    // shouldReturn = true;
                                    player.frameCounter = 0;
                                    player.hardDropCoordinates = null;
                                    player.hardDropFinalCoordinates(this);
                              }
                        }
                  });
            }
            requestAnimationFrame(this.gameLoop.bind(this));
      };

      checkGameOver = () => {
            const gameOverPlayers = [];
            const playingPlayers = [];
            this.players.forEach((player, index) => {
                  if (!player.isGameOver) {
                        playingPlayers.push(player);
                  } else {
                        gameOverPlayers.push(player);
                  }
            });

            gameOverPlayers.sort((a, b) => {
                  return b.stats.score - a.stats.score;
            });

            if (playingPlayers.length === 0) {
                  this.sounds.winningSound.play();
                  this.isGameOver = true;
                  this.renderGameResult(true);
                  const highestScore = gameOverPlayers[0].stats.score;
                  gameOverPlayers.forEach((player) => {
                        if (player.stats.score === highestScore) {
                              this.splitScreenMode.winners.push(player);
                        } else {
                              this.splitScreenMode.losers.push(player);
                        }
                  });

                  return true;
            } else if (playingPlayers.length === 1) {
                  if (
                        playingPlayers[0].stats.score >
                        gameOverPlayers[0].stats.score
                  ) {
                        this.sounds.winningSound.play();
                        this.isGameOver = true;
                        this.renderGameResult(true);
                        this.splitScreenMode.winners.push(playingPlayers[0]);
                        this.splitScreenMode.losers.push(...gameOverPlayers);

                        return true;
                  }
            }
            return false;
      };
      onJoyStickConnect = () => {
            window.addEventListener("gamepadconnected", (event) => {
                  console.log(event.gamepad);
                  if (this.connectedJoysticks === 4) {
                        toast.error("maximum 4 joysticks allowed");
                        return;
                  }
                  for (let i = 0; i < this.joysticks.length; i++) {
                        if (this.joysticks[i] === null) {
                              this.joysticks[i] = new Joystick(
                                    event.gamepad,
                                    i,
                                    this
                              );

                              this.setShowGamePadState({});
                              toast.success(
                                    `player ${i} connected`,
                                    toastOptions
                              );
                              this.connectedJoysticks++;
                              return;
                        }
                  }
            });
      };
      onJoystickDisconnect = () => {
            window.addEventListener("gamepaddisconnected", (event) => {
                  for (let i = 0; i < this.joysticks.length; i++) {
                        if (
                              this.joysticks[i] &&
                              this.joysticks[i].gamepad.index ===
                                    event.gamepad.index
                        ) {
                              this.joysticks[i] = null;
                              this.connectedJoysticks--;
                              this.setShowGamePadState({});
                              toast.error(
                                    `player ${i} disconnected`,
                                    toastOptions
                              );
                        }
                  }
                  if (this.joysticks[0] === null) {
                        for (let i = 0; i < this.joysticks.length; i++) {
                              if (this.joysticks[i] !== null) {
                                    this.joysticks[0] = this.joysticks[i];
                                    this.joysticks[i] = null;
                                    break;
                              }
                        }
                  }
            });
      };

      onKeyboard = () => {
            window.addEventListener("keydown", (event) => {
                  if (this.isGameStarted && !this.pause && !this.isGameOver) {
                        if (
                              !this.keyBoard.keyBoardMapping[event.key] ||
                              !this.keyBoard.keyBoardMapping[event.key]
                                    .playerNumber
                        ) {
                              return;
                        }
                        const playerNumber =
                              this.keyBoard.keyBoardMapping[event.key]
                                    .playerNumber;
                        const bindingValue =
                              this.keyBoard.keyBoardMapping[event.key]
                                    .bindingValue;
                        if (
                              this.players[playerNumber] &&
                              !this.players[playerNumber].destroyInAction
                        ) {
                              finalInput(
                                    bindingValue,
                                    this.players[playerNumber],
                                    this.sounds,
                                    null,
                                    this
                              );
                        }

                        this.players.forEach((player) => {
                              player.hardDropCoordinates = null;
                              player.hardDropFinalCoordinates(this);
                        });
                  }
            });
      };

      start = () => {
            this.isGameStarted = true;
            this.renderPlayersUi({});
      };

      reset = () => {
            this.time = 0;
            this.CoordinatesAndColorsOfTetrominos = tetrominoes(10);
            this.singlePlayerMode.patternToMatch = [];
            this.singlePlayerModechallengeWon = null;
            this.singlePlayerMode.rating = 0;
            this.singlePlayerMode.bestRating = 0;

            this.splitScreenMode.patternToMatch = [];
            this.splitScreenMode.numberOfPlayersFinished = 0;
            this.splitScreenMode.maxNumberOfPlayers = 2;
            this.splitScreenMode.gameOverPlayers = [];
            this.splitScreenMode.playersStandings = [];
            this.splitScreenMode.winners = [];
            this.splitScreenMode.losers = [];

            this.boardRows = 20;
            this.boardColumns = 10;
            this.fixedFrameRate = {
                  lastRan: -1,
                  timeElapsed: 0,
                  averageFps: 0,
                  currentFps: 0,
                  currentFrameTime: 0,
                  framesCounter: 0,
            };
            this.playersStandings = null;
            this.isGameOver = false;
            this.isGameStarted = false;
            this.pause = false;
            this.randomTetrominoIndexes = this.generateNewTetrominoIndexes([
                  "l tetromino",
                  "t tetromino",
                  "square tetromino",
                  "line tetromino",
                  "z tetromino",
            ]);
            this.gameLoopWaitCount = 0;
            this.players.forEach((player) => {
                  player.reset(
                        this.CoordinatesAndColorsOfTetrominos[
                              this.randomTetrominoIndexes[0]
                        ],
                        this.speed
                  );
            });
      };

      generateNewTetrominoIndexes = (
            shapes = [
                  "l tetromino",
                  "t tetromino",
                  "square tetromino",
                  "line tetromino",
                  "z tetromino",
            ],
            totalCount = 500
      ) => {
            let a = [];
            const shapesIndexes = [];
            this.CoordinatesAndColorsOfTetrominos.forEach(
                  (tetromino, index) => {
                        shapes.forEach((shape) => {
                              if (tetromino.name === shape) {
                                    shapesIndexes.push(index);
                              }
                        });
                  }
            );

            for (let i = 0; i < totalCount; i++) {
                  const randomInteger = Math.floor(
                        Math.random() * shapesIndexes.length
                  );
                  a.push(shapesIndexes[randomInteger]);
            }

            return a;
      };
}

export class Player {
      stats;
      isGameOver;
      number;
      currentTetromino;
      boardMatrix;
      renderUi;
      currentIndexOfrandomTetrominoIndexes;
      hardDropCoordinates;
      previousSpeed;
      currentSpeed;
      frameCounter;
      time;
      lifeSaverCount;
      destroyInAction;
      numberOfDestroyedRows;
      patternMatching;

      constructor(
            playerNumber,
            startingTetromino,
            boardRows,
            boardColumns,
            speed
      ) {
            this.patternMatching = {
                  currentIndex: 0,
                  bestPerformance: 0,
            };
            this.destroyInAction = false;
            this.numberOfDestroyedRows = 0;
            this.previousSpeed = speed;
            this.currentSpeed = speed;
            this.frameCounter = 0;
            this.stats = {
                  score: 0,
                  singleShots: 0,
                  doubleShots: 0,
                  tripleShots: 0,
                  quadraShots: 0,
            };
            this.lifeSaverCount = 1;
            this.time = 0;
            this.hardDropCoordinates = null;
            this.isGameOver = false;
            this.number = playerNumber;
            this.currentIndexOfrandomTetrominoIndexes = 0;
            this.currentTetromino = {
                  allCoordinates: deepCloneArray(
                        startingTetromino.allCoordinates
                  ),
                  colorClass: startingTetromino.colorClass,
            };
            this.boardMatrix = createPlayerBoardMatrix(boardRows, boardColumns);
            this.renderUi = null;
      }

      reset = (startingTetromino, speed) => {
            this.patternMatching.currentIndex = 0;
            this.patternMatching.bestPerformance = 0;
            this.destroyInAction = false;
            this.lifeSaverCount = 1;
            this.numberOfDestroyedRows = 0;
            this.isGameOver = false;
            this.currentSpeed = speed;
            this.previousSpeed = speed;
            this.frameCounter = 0;
            this.hardDropCoordinates = null;
            this.stats.score = 0;
            this.stats.singleShots = 0;
            this.stats.doubleShots = 0;
            this.stats.tripleShots = 0;
            this.stats.quadraShots = 0;

            this.currentIndexOfrandomTetrominoIndexes = 0;
            this.currentTetromino = {
                  allCoordinates: deepCloneArray(
                        startingTetromino.allCoordinates
                  ),
                  colorClass: startingTetromino.colorClass,
            };
            this.boardMatrix = createPlayerBoardMatrix(20, 10);
            this.time = 0;
      };

      hardDropFinalCoordinates = (game) => {
            let currentTetromino = this.currentTetromino;
            let flag = false;

            while (
                  isPossibleToMove("ArrowDown", game, {
                        currentTetromino,
                        boardMatrix: this.boardMatrix,
                  })
            ) {
                  currentTetromino = moveDown(currentTetromino);
                  flag = true;
            }
            if (flag) {
                  this.hardDropCoordinates = currentTetromino;
            }
      };
}

export class Joystick {
      previousPressedButtonIndex;
      throttleCount;
      gamepad;
      mappedPlayerNumber;
      defaultKeyBindings;
      navigationKeyBindings;
      buttonsNames;
      constructor(gamepad, player, game) {
            this.previousPressedButtonIndex = null;
            this.throttleCount = 0;
            this.gamepad = gamepad;
            this.mappedPlayerNumber = player;
            this.defaultKeyBindings =
                  game.userSavedData.settings.controls.controller[player];
            this.buttonsNames = [
                  "a",
                  "b",
                  "x",
                  "y",
                  "l1",
                  "r1",
                  "l2",
                  "r2",
                  null,
                  null,
                  null,
                  null,
                  "dpad up",
                  "dpad down",
                  "dpad left",
                  "dpad right",
                  null,
            ];
            this.navigationKeyBindings = [
                  null,
                  "Back",
                  "Enter",
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  "Escape",
                  null,
                  null,
                  "ArrowUp",
                  "ArrowDown",
                  "ArrowLeft",
                  "ArrowRight",
                  null,
            ];
      }

      updateGameKeyBinding = (buttonIndex, bindingValue) => {
            for (let i = 0; i < this.defaultKeyBindings.length; i++) {
                  if (this.defaultKeyBindings[i] === bindingValue) {
                        this.defaultKeyBindings[i] =
                              this.defaultKeyBindings[buttonIndex];
                        this.defaultKeyBindings[buttonIndex] = bindingValue;
                  }
            }

            return true;
      };
}

export class Touch {
      defaultKeyBindings;
      buttonsNames;
      constructor(game) {
            this.defaultKeyBindings =
                  game.userSavedData.settings.controls.touch;
            this.buttonsNames = [
                  "a",
                  "b",
                  "x",
                  "y",
                  "l1",
                  "r1",
                  "l2",
                  "r2",
                  null,
                  null,
                  null,
                  null,
                  "dpad up",
                  "dpad down",
                  "dpad left",
                  "dpad right",
                  null,
            ];
      }

      updateGameKeyBinding = (buttonIndex, bindingValue) => {
            for (let i = 0; i < this.defaultKeyBindings.length; i++) {
                  if (this.defaultKeyBindings[i] === bindingValue) {
                        this.defaultKeyBindings[i] =
                              this.defaultKeyBindings[buttonIndex];
                        this.defaultKeyBindings[buttonIndex] = bindingValue;
                  }
            }

            return true;
      };
}
export class KeyBoard {
      keyBoardMapping;
      constructor(game) {
            this.keyBoardMapping =
                  game.userSavedData.settings.controls.keyBoard;
      }

      updateKeyBoardMapping(
            key,
            playerNumber,
            bindingValue,
            assignedKeyBoardKey
      ) {
            if (assignedKeyBoardKey === "space bar") {
                  assignedKeyBoardKey = " ";
            }

            if (assignedKeyBoardKey) {
                  this.keyBoardMapping[assignedKeyBoardKey].playerNumber =
                        this.keyBoardMapping[key].playerNumber;

                  this.keyBoardMapping[assignedKeyBoardKey].bindingValue =
                        this.keyBoardMapping[key].bindingValue;
            }
            this.keyBoardMapping[key].playerNumber = playerNumber;
            this.keyBoardMapping[key].bindingValue = bindingValue;
            return true;
      }
}

export const moveRight = (currentTetromino) => {
      const newTetromino = [];
      currentTetromino.allCoordinates.forEach((coordinates, index) => {
            newTetromino[index] = [coordinates[0], coordinates[1] + 1];
      });

      return {
            colorClass: currentTetromino["colorClass"],
            allCoordinates: newTetromino,
      };
};

export const moveDown = (currentTetromino) => {
      const newTetromino = [];
      currentTetromino.allCoordinates.forEach((coordinates, index) => {
            newTetromino[index] = [coordinates[0] + 1, coordinates[1]];
      });

      return {
            colorClass: currentTetromino["colorClass"],
            allCoordinates: newTetromino,
      };
};
export const moveLeft = (currentTetromino) => {
      const newTetromino = [];
      currentTetromino.allCoordinates.forEach((coordinates, index) => {
            newTetromino[index] = [coordinates[0], coordinates[1] - 1];
      });

      return {
            colorClass: currentTetromino["colorClass"],
            allCoordinates: newTetromino,
      };
};

export const isPossibleToMove = (direction, game, player) => {
      if (direction === "ArrowLeft") {
            return player.currentTetromino.allCoordinates.every(
                  (coordinates) => {
                        return (
                              coordinates[1] > 0 &&
                              !player.boardMatrix[coordinates[0]][
                                    coordinates[1] - 1
                              ]
                        );
                  }
            );
      } else if (direction === "ArrowRight") {
            return player.currentTetromino.allCoordinates.every(
                  (coordinates) => {
                        return (
                              coordinates[1] < game.boardColumns - 1 &&
                              !player.boardMatrix[coordinates[0]][
                                    coordinates[1] + 1
                              ]
                        );
                  }
            );
      } else if (direction === "ArrowDown") {
            return player.currentTetromino.allCoordinates.every(
                  (coordinates) => {
                        return (
                              coordinates[0] < game.boardRows - 1 &&
                              !player.boardMatrix[coordinates[0] + 1][
                                    coordinates[1]
                              ]
                        );
                  }
            );
      } else if ("startingPosition") {
            return player.currentTetromino.allCoordinates.every(
                  (coordinates) => {
                        return !player.boardMatrix[coordinates[0]][
                              coordinates[1]
                        ];
                  }
            );
      }
};

export const createPlayerBoardMatrix = (boardRows, boardColumns) => {
      const playerBoardMatrix = [];

      for (let i = 0; i < boardRows; i++) {
            playerBoardMatrix[i] = [];

            for (let j = 0; j < boardColumns + 1; j++) {
                  if (j === boardColumns) {
                        playerBoardMatrix[i][j] = 0;
                  } else {
                        playerBoardMatrix[i].push("");
                  }
            }
      }
      return playerBoardMatrix;
};

export const updateplayerBoardMatrix = (player, game) => {
      player.currentTetromino.allCoordinates.forEach((coordinates) => {
            player.boardMatrix[coordinates[0]][game.boardColumns]++;
            player.boardMatrix[coordinates[0]][coordinates[1]] =
                  player.currentTetromino.colorClass;
      });
};

export const isRotationPossible = (game, player) => {
      const x = player.currentTetromino.allCoordinates;
      const y = player.boardMatrix;
      const setOfrotatedCoordinates = x.map((coordinates) => {
            return [
                  coordinates[1] - x[0][1] + x[0][0],
                  -(coordinates[0] - x[0][0]) + x[0][1],
            ];
      });

      const output = setOfrotatedCoordinates.every((coordinates) => {
            return (
                  coordinates[0] < game.boardRows &&
                  coordinates[0] > -1 &&
                  coordinates[1] < game.boardColumns - 1 &&
                  coordinates[1] > -1 &&
                  !y[coordinates[0]][coordinates[1]]
            );
      });

      if (output) {
            return setOfrotatedCoordinates;
      } else {
            return false;
      }
};

export const areThereAnydestroyableRows = (game, player) => {
      let destroyableRows = new Set();
      player.currentTetromino.allCoordinates.forEach((coordinates) => {
            if (
                  player.boardMatrix[coordinates[0]][game.boardColumns] ===
                  game.boardColumns
            ) {
                  destroyableRows.add(coordinates[0]);
            }
      });

      destroyableRows = [...destroyableRows].sort((a, b) => {
            return a - b;
      });

      return destroyableRows;
};

export const updateStats = (player, numberOfDestroyedRows, game) => {
      if (numberOfDestroyedRows === 1) {
            player.stats.singleShots++;
      } else if (numberOfDestroyedRows === 2) {
            player.stats.doubleShots++;
      } else if (numberOfDestroyedRows === 3) {
            player.stats.tripleShots++;
      } else if (numberOfDestroyedRows === 4) {
            player.stats.quadraShots++;
      }
      player.stats.score += numberOfDestroyedRows * 100 * numberOfDestroyedRows;
};

export const shiftBlocks = (destroyableRows, player, game) => {
      destroyableRows.forEach((row) => {
            let currentRow = row;
            let upperRow = row - 1;
            while (
                  upperRow !== -1 &&
                  player.boardMatrix[upperRow][game.boardColumns]
            ) {
                  player.boardMatrix[currentRow].forEach((element, column) => {
                        if (column === game.boardColumns) {
                              player.boardMatrix[currentRow][
                                    game.boardColumns
                              ] =
                                    player.boardMatrix[upperRow][
                                          game.boardColumns
                                    ];
                              player.boardMatrix[upperRow][
                                    game.boardColumns
                              ] = 0;
                        } else {
                              player.boardMatrix[currentRow][column] =
                                    player.boardMatrix[upperRow][column];

                              player.boardMatrix[upperRow][column] = "";
                        }
                  });

                  currentRow--;
                  upperRow--;
            }
      });
};
export const getSavedData = async function () {
      const savedData = await JSON.parse(localStorage.getItem("userDataOne"));
      if (!savedData) {
            const newSavedData = {};
            newSavedData.stats = {
                  singlePlayerMode: {
                        easy: {},
                  },
            };

            newSavedData.settings = {
                  controls: {
                        controller: [],
                        keyboard: [],
                  },
                  skin: "theme-0",
            };

            localStorage.setItem("userDataOne", JSON.stringify(newSavedData));
            return newSavedData;
      }

      return savedData;
};
//
export const destroy = (destroyableRows, game, player) => {
      let column = 0;
      let throttleCount = 0;
      game.sounds.blasterSound.play();
      player.destroyInAction = true;

      const destroyAnimationLoop = () => {
            throttleCount++;
            if (throttleCount === 2) {
                  if (column === game.boardColumns) {
                        destroyableRows.forEach((row) => {
                              player.boardMatrix[row][column] = 0;
                              const laserBeam = document.querySelector(
                                    `#player-${player.number}-laser-beam-${row}`
                              );
                              laserBeam.style.width = "0px";
                        });
                        shiftBlocks(destroyableRows, player, game);
                        player.renderUi({});
                        player.destroyInAction = false;
                        return;
                  }

                  destroyableRows.forEach((row) => {
                        player.boardMatrix[row][column] = "";
                        const laserBeam = document.querySelector(
                              `#player-${player.number}-laser-beam-${row}`
                        );

                        laserBeam.style.width = 25 * (column + 1) + "px";
                  });
                  column++;

                  player.renderUi({});
                  throttleCount = 0;
            }
            requestAnimationFrame(destroyAnimationLoop);
      };
      requestAnimationFrame(destroyAnimationLoop);
};

export const finalInput = (direction, player, sounds, gamepad, game) => {
      let returnValue = false;
      if (player.isGameOver) {
            return false;
      }

      if (direction === "lifeSaver" && player.lifeSaverCount) {
            let firstNonEmptyRow = -1;

            for (let row = 0; row < game.boardRows; row++) {
                  if (player.boardMatrix[row][game.boardColumns]) {
                        firstNonEmptyRow = row;
                        break;
                  }
            }
            if (firstNonEmptyRow !== -1) {
                  const destroyableRows = [firstNonEmptyRow];
                  firstNonEmptyRow++;
                  while (
                        destroyableRows.length < 5 &&
                        firstNonEmptyRow < game.boardRows
                  ) {
                        destroyableRows.push(firstNonEmptyRow);
                        firstNonEmptyRow++;
                  }

                  player.numberOfDestroyedRows = 0;
                  destroy(destroyableRows, game, player);

                  if (gamepad) {
                        let duration = (destroyableRows.length - 1) * 200 + 200;
                        gamepad.vibrationActuator?.playEffect("dual-rumble", {
                              startDelay: 0,
                              duration: duration,
                              weakMagnitude: 1.0,
                              strongMagnitude: 1.0,
                        });
                  }
                  player.lifeSaverCount--;
            }
      } else if (direction === "ArrowDown") {
            if (isPossibleToMove("ArrowDown", game, player)) {
                  const newTetromino = moveDown(player.currentTetromino);

                  if (sounds) {
                        if (!sounds.fall.playing()) {
                              sounds.fall.play();
                        }
                  }
                  player.currentTetromino = newTetromino;
                  player.renderUi({});
                  returnValue = true;
            }
      } else if (direction === "ArrowLeft") {
            if (isPossibleToMove("ArrowLeft", game, player)) {
                  const newTetromino = moveLeft(player.currentTetromino);

                  if (sounds) {
                        if (!sounds.fall.playing()) {
                              sounds.fall.play();
                        }
                  }
                  player.currentTetromino = newTetromino;
                  player.renderUi({});
                  returnValue = true;
            }
      } else if (direction === "ArrowRight") {
            if (isPossibleToMove("ArrowRight", game, player)) {
                  const newTetromino = moveRight(player.currentTetromino);

                  if (sounds) {
                        if (!sounds.fall.playing()) {
                              sounds.fall.play();
                        }
                  }
                  player.currentTetromino = newTetromino;
                  player.renderUi({});
                  returnValue = true;
            }
      } else if (direction === " " || direction === "rotate") {
            const setOfrotatedCoordinates = isRotationPossible(game, player);
            if (setOfrotatedCoordinates) {
                  if (sounds) {
                        if (!sounds.fall.playing()) {
                              sounds.fall.play();
                        }
                  }
                  player.currentTetromino = {
                        allCoordinates: setOfrotatedCoordinates,
                        colorClass: player.currentTetromino.colorClass,
                  };
                  player.renderUi({});
                  returnValue = true;
            } else {
                  if (sounds) {
                        if (!sounds.rotateBlockSound.playing()) {
                              sounds.rotateBlockSound.play();
                        }
                  }
            }
      } else if (direction === "startingPosition") {
            if (isPossibleToMove("startingPosition", game, player)) {
                  returnValue = true;
            }
      } else if (direction === "hardDrop") {
            game?.sounds?.whipSound.play();
            if (player.currentSpeed !== 1) {
                  player.previousSpeed = player.currentSpeed;
            }
            player.currentSpeed = 1;
            player.frameCounter = 0;
            // if (game) {
            //       game.speed = 2;
            //       game.gameLoopWaitCount = 0;
            // }
            // player.hardDropCoordinates = null;
            // player.hardDropFinalCoordinates();
            // if (player.hardDropCoordinates) {
            //       player.currentTetromino = player.hardDropCoordinates;
            //       player.renderUi({});
            //       returnValue = true;
            // }
      }
      player.hardDropCoordinates = null;
      player.hardDropFinalCoordinates(game);
      return returnValue;
};

export const navigationGamepadLoop = (
      game,
      controllerSettingsOverlayKeyDownHandler,
      gamepadLoopState
) => {
      let joystick = null;
      game.joysticks.forEach((element) => {
            if (joystick === null && element !== null) {
                  joystick = element;
            }
      });

      let shouldExit = false;

      if (joystick) {
            if (joystick.throttleCount < 10) {
                  joystick.throttleCount++;
            }
            const gamepads = navigator.getGamepads();
            const gamepad = gamepads[joystick.gamepad.index];
            if (gamepad) {
                  gamepad.buttons.forEach((button, buttonIndex) => {
                        if (button.pressed) {
                              if (
                                    joystick.previousPressedButtonIndex !==
                                    buttonIndex
                              ) {
                                    controllerSettingsOverlayKeyDownHandler({
                                          key: joystick.navigationKeyBindings[
                                                buttonIndex
                                          ],
                                    });
                                    joystick.previousPressedButtonIndex =
                                          buttonIndex;
                                    joystick.throttleCount = 0;
                              } else if (joystick.throttleCount === 10) {
                                    controllerSettingsOverlayKeyDownHandler({
                                          key: joystick.navigationKeyBindings[
                                                buttonIndex
                                          ],
                                    });
                                    joystick.throttleCount = 0;
                              }
                        }
                  });
            }
      }

      if (shouldExit || !gamepadLoopState.run) {
            return;
      }

      requestAnimationFrame(
            navigationGamepadLoop.bind(
                  null,
                  game,
                  controllerSettingsOverlayKeyDownHandler,
                  gamepadLoopState
            )
      );
};

export const tetrominoes = (boardColumns) => {
      let midpoint = -1;
      if (boardColumns % 2 === 0) {
            midpoint = boardColumns / 2 - 1;
            return [
                  {
                        allCoordinates: [
                              [0, midpoint],
                              [1, midpoint],
                              [2, midpoint],
                              [2, midpoint + 1],
                        ],
                        colorClass: "l-tetromino-active",
                        name: "l tetromino",
                  }, // l tetromino
                  {
                        allCoordinates: [
                              [0, midpoint - 1],
                              [0, midpoint],
                              [0, midpoint + 1],
                              [1, midpoint],
                        ],
                        colorClass: "t-tetromino-active",
                        name: "t tetromino",
                  }, // t tetromino

                  {
                        allCoordinates: [
                              [0, midpoint],
                              [0, midpoint + 1],
                              [1, midpoint],
                              [1, midpoint + 1],
                        ],
                        colorClass: "square-tetromino-active",
                        name: "square tetromino",
                  }, // square

                  {
                        allCoordinates: [
                              [0, midpoint - 1],
                              [0, midpoint],
                              [0, midpoint + 1],
                              [0, midpoint + 2],
                        ],
                        colorClass: "line-tetromino-active",
                        name: "line tetromino",
                  }, // line tetromino

                  {
                        allCoordinates: [
                              [0, midpoint],
                              [1, midpoint],
                              [1, midpoint + 1],
                              [2, midpoint + 1],
                        ],
                        colorClass: "z-tetromino-active",
                        name: "z tetromino",
                  }, // skew tetromino
            ];
      } else {
            midpoint = (boardColumns + 1) / 2 - 1;
            return [
                  {
                        allCoordinates: [
                              [0, midpoint],
                              [1, midpoint],
                              [2, midpoint],
                              [2, midpoint + 1],
                        ],
                        colorClass: "l-tetromino-active",
                        name: "l tetromino",
                  }, // l tetromino
                  {
                        allCoordinates: [
                              [0, midpoint - 1],
                              [0, midpoint],
                              [0, midpoint + 1],
                              [1, midpoint],
                        ],
                        colorClass: "t-tetromino-active",
                        name: "t tetromino",
                  }, // t tetromino

                  {
                        allCoordinates: [
                              [0, midpoint - 1],
                              [0, midpoint],
                              [1, midpoint - 1],
                              [1, midpoint],
                        ],
                        colorClass: "square-tetromino-active",
                        name: "square tetromino",
                  }, // square

                  {
                        allCoordinates: [
                              [0, midpoint - 2],
                              [0, midpoint - 1],
                              [0, midpoint],
                              [0, midpoint + 1],
                        ],
                        colorClass: "line-tetromino-active",
                        name: "line tetromino",
                  }, // line tetromino

                  {
                        allCoordinates: [
                              [0, midpoint],
                              [1, midpoint],
                              [1, midpoint + 1],
                              [2, midpoint + 1],
                        ],
                        colorClass: "z-tetromino-active",
                        name: "z tetromino",
                  }, // skew tetromino
            ];
      }
};

const deepCloneArray = (input) => {
      return input.map((element) =>
            Array.isArray(element) ? deepCloneArray(element) : element
      );
};

const shiftElementsOfArray = (input, index) => {
      for (let i = index; i < input.length - 1; i++) {
            input[i] = input[i + 1];
            input[i + 1] = null;
      }
};

export const singlePlayerChallengeCompleted = (game, player) => {
      player.isGameOver = true;
      game.sounds.winningSound.play();
      game.isGameOver = true;
      game.renderGameResult(true);
      game.singlePlayerMode.challengeWon = "yes";
      const difficulty = game.singlePlayerMode.difficulty;
      const challengeName = game.singlePlayerMode.challenge.id;
      const challengeStats =
            game.userSavedData.stats["singlePlayerMode"][difficulty][
                  challengeName
            ];

      if (!challengeStats) {
            game.userSavedData.stats["singlePlayerMode"][difficulty][
                  challengeName
            ] = {
                  done: "yes",
                  attempts: 1,
                  attemptsTakenToPass: 1,
                  howCloseToTarget: 100,
            };
      } else if (challengeStats.done !== "yes") {
            challengeStats.done = "yes";
            challengeStats.attempts++;
            challengeStats.attemptsTakenToPass = challengeStats.attempts;

            challengeStats.howCloseToTarget = 100;
      } else {
            challengeStats.attempts++;
            if (!challengeStats.hasOwnProperty("howCloseToTarget")) {
                  challengeStats.howCloseToTarget = 100;
            }
      }

      game.saveData();
};

export const saveData = (game) => {
      localStorage.setItem("userDataOne", JSON.stringify(game.userSavedData));
};

export const singlePlayerChallengeLost = (game, player, howCloseToTarget) => {
      game.singlePlayerMode.rating = howCloseToTarget;

      player.isGameOver = true;
      game.sounds.gameOverSound.play();
      game.isGameOver = true;
      game.renderGameResult(true);
      game.singlePlayerMode.challengeWon = "no";

      const difficulty = game.singlePlayerMode.difficulty;
      const challengeName = game.singlePlayerMode.challenge.id;
      const challengeStats =
            game.userSavedData.stats["singlePlayerMode"][difficulty][
                  challengeName
            ];

      if (!challengeStats) {
            game.userSavedData.stats["singlePlayerMode"][difficulty][
                  challengeName
            ] = {
                  done: "no",
                  attempts: 1,
                  howCloseToTarget,
            };
      } else {
            challengeStats.attempts++;

            if (!challengeStats.hasOwnProperty("howCloseToTarget")) {
                  challengeStats.howCloseToTarget = howCloseToTarget;
            } else {
                  game.singlePlayerMode.bestRating =
                        challengeStats.howCloseToTarget;
                  if (challengeStats.howCloseToTarget < howCloseToTarget) {
                        challengeStats.howCloseToTarget = howCloseToTarget;
                  }
            }
      }

      game.saveData();
};

export const challenges = {
      singlePlayer: {
            easy: [
                  {
                        name: " clear 7 lines in 60 seconds",
                        id: 0,
                        conditions: (game, player) => {
                              const totalLinesCleared =
                                    calculateTotalLinesCleared(player);

                              if (totalLinesCleared >= 7) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (
                                    player.isGameOver ||
                                    player.time >= 60
                              ) {
                                    const howCloseToTarget = Math.round(
                                          (totalLinesCleared / 7) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {},
                  },
                  {
                        name: "achieve a score of 1500",
                        id: 1,
                        conditions: (game, player) => {
                              if (player.stats.score >= 1500) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (player.isGameOver) {
                                    const howCloseToTarget = Math.round(
                                          (player.stats.score / 1500) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {},
                  },
                  {
                        name: "Clear 5 lines using only t shaped tetrominos",
                        id: 2,
                        conditions: (game, player) => {
                              const totalLinesCleared =
                                    calculateTotalLinesCleared(player);

                              if (totalLinesCleared >= 5) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (player.isGameOver) {
                                    const howCloseToTarget = Math.round(
                                          (totalLinesCleared / 5) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {
                              game.randomTetrominoIndexes =
                                    game.generateNewTetrominoIndexes(
                                          ["t tetromino"],
                                          500
                                    );
                              assignStartingTetrominoToAllPlayers(game);
                        },
                  },
                  {
                        name: "achieve two 4x shots in 3 minutes",
                        id: 3,
                        conditions: (game, player) => {
                              if (player.stats.quadraShots >= 2) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (
                                    player.isGameOver ||
                                    player.time >= 180
                              ) {
                                    const howCloseToTarget = Math.round(
                                          (player.stats.quadraShots / 2) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {},
                  },
                  {
                        name: "clear 15 lines in 3 minutes",
                        id: 4,
                        conditions: (game, player) => {
                              const totalLinesCleared =
                                    calculateTotalLinesCleared(player);

                              if (totalLinesCleared >= 15) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (
                                    player.isGameOver ||
                                    player.time >= 180
                              ) {
                                    const howCloseToTarget = Math.round(
                                          (totalLinesCleared / 15) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {},
                  },
                  {
                        name: "clear 7 lines using only l-shaped,z shaped tetrominos",
                        id: 5,
                        conditions: (game, player) => {
                              const totalLinesCleared =
                                    calculateTotalLinesCleared(player);

                              if (totalLinesCleared >= 7) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (player.isGameOver) {
                                    const howCloseToTarget = Math.round(
                                          (totalLinesCleared / 7) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {
                              game.randomTetrominoIndexes =
                                    game.generateNewTetrominoIndexes(
                                          ["l tetromino", "z tetromino"],
                                          500
                                    );
                              assignStartingTetrominoToAllPlayers(game);
                        },
                  },
                  {
                        name: "achieve four 2x shots in 3 minutes",
                        id: 6,
                        conditions: (game, player) => {
                              const { doubleShots } = player.stats;
                              if (doubleShots >= 4) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (
                                    player.isGameOver ||
                                    player.time >= 180
                              ) {
                                    const howCloseToTarget = Math.round(
                                          (doubleShots / 4) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {},
                  },
                  {
                        name: " speed is 1.5x, clear 10 lines in 3 minutes",
                        id: 7,
                        conditions: (game, player) => {
                              const totalLinesCleared =
                                    calculateTotalLinesCleared(player);

                              if (totalLinesCleared >= 10) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (
                                    player.isGameOver ||
                                    player.time >= 180
                              ) {
                                    const howCloseToTarget = Math.round(
                                          (totalLinesCleared / 10) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {
                              game.players.forEach((player) => {
                                    player.currentSpeed = 20;
                                    player.previousSpeed = 20;
                              });
                        },
                  },
                  {
                        name: "achieve a score of 5000",
                        id: 8,
                        conditions: (game, player) => {
                              if (player.stats.score >= 5000) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (player.isGameOver) {
                                    const howCloseToTarget = Math.round(
                                          (player.stats.score / 5000) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {},
                  },
            ],
            medium: [
                  {
                        id: 9,
                        name: "match the pattern of clearing lines (2x, 2x, 2x), resets to starting if you miss the order",
                        conditions: (game, player, destroyableRows) => {
                              if (
                                    game.singlePlayerMode.patternToMatch[
                                          player.patternMatching.currentIndex
                                    ] === destroyableRows.length
                              ) {
                                    player.patternMatching.currentIndex++;
                                    if (
                                          player.patternMatching
                                                .bestPerformance <
                                          player.patternMatching.currentIndex
                                    ) {
                                          player.patternMatching.bestPerformance =
                                                player.patternMatching.currentIndex;
                                    }
                              } else if (destroyableRows.length > 0) {
                                    player.patternMatching.currentIndex = 0;
                              }
                              if (
                                    player.patternMatching.currentIndex ===
                                    game.singlePlayerMode.patternToMatch.length
                              ) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (player.isGameOver) {
                                    const howCloseToTarget = Math.round(
                                          (player.patternMatching
                                                .bestPerformance /
                                                game.singlePlayerMode
                                                      .patternToMatch.length) *
                                                100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {
                              game.singlePlayerMode.patternToMatch = [2, 2, 2];
                        },
                  },
                  {
                        id: 10,
                        name: "2x speed for the first minute, survive the first 1 minute without clearing lines and then achieve a score of 8000",
                        conditions: (game, player, destroyableRows) => {
                              if (player.time === 61) {
                                    player.currentSpeed = 30;
                                    player.previouSpeed = 30;
                              }
                              if (player.stats.score > 0 && player.time <= 60) {
                                    singlePlayerChallengeLost(game, player, 0);
                              } else if (player.stats.score >= 8000) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (player.isGameOver) {
                                    const howCloseToTarget = Math.round(
                                          (player.stats.score / 8000) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {
                              game.players.forEach((player) => {
                                    player.currentSpeed = 15;
                                    player.previouSpeed = 15;
                              });
                        },
                  },
                  {
                        id: 11,
                        name: "4x speed at every 20s from the time it ends for 15s, survive for 5 minutes without loosing.",
                        conditions: (game, player, destroyableRows) => {
                              if (
                                    player.time === 20 ||
                                    player.time === 55 ||
                                    player.time === 90 ||
                                    player.time === 125 ||
                                    player.time === 160 ||
                                    player.time === 195 ||
                                    player.time === 230 ||
                                    player.time === 265
                              ) {
                                    if (player.currentSpeed !== 2) {
                                          player.currentSpeed = 8;
                                    }

                                    player.previousSpeed = 8;
                              } else if (
                                    player.time === 0 ||
                                    player.time === 36 ||
                                    player.time === 71 ||
                                    player.time === 106 ||
                                    player.time === 141 ||
                                    player.time === 176 ||
                                    player.time === 211 ||
                                    player.time === 246
                              ) {
                                    if (player.currentSpeed !== 2) {
                                          player.currentSpeed = 30;
                                    }

                                    player.previousSpeed = 30;
                              }

                              if (player.time >= 300) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (player.isGameOver) {
                                    const howCloseToTarget = Math.round(
                                          (player.time / 300) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {
                              game.players.forEach((player) => {
                                    player.currentSpeed = 6;
                                    player.previouSpeed = 6;
                              });
                        },
                  },
                  {
                        id: 12,
                        name: "speed is 3x, achieve a score of 8000",
                        conditions: (game, player, destroyableRows) => {
                              if (player.stats.score >= 8000) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (player.isGameOver) {
                                    const howCloseToTarget = Math.round(
                                          (player.stats.score / 8000) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {
                              game.players.forEach((player) => {
                                    player.currentSpeed = 10;
                                    player.previouSpeed = 10;
                              });
                        },
                  },
                  {
                        id: 13,
                        name: "clear 25 lines in 3 minutes",
                        conditions: (game, player, destroyableRows) => {
                              const totalLinesCleared =
                                    calculateTotalLinesCleared(player);
                              if (totalLinesCleared >= 25) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (
                                    player.isGameOver ||
                                    player.time >= 180
                              ) {
                                    const howCloseToTarget = Math.round(
                                          (totalLinesCleared / 25) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {},
                  },
                  {
                        id: 14,
                        name: "achieve three 4x shots, three 3x shots, two 2x shots in 5 minutes",
                        initialize: (game) => {},
                        conditions: (game, player, destroyableRows) => {
                              const { doubleShots, tripleShots, quadraShots } =
                                    player.stats;

                              if (
                                    quadraShots >= 3 &&
                                    tripleShots >= 3 &&
                                    doubleShots >= 2
                              ) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              }
                              if (player.isGameOver) {
                                    const minDoubleShots = Math.min(
                                          2,
                                          doubleShots
                                    );
                                    const minTripleShots = Math.min(
                                          3,
                                          tripleShots
                                    );
                                    const minQuadraShots = Math.min(
                                          3,
                                          quadraShots
                                    );

                                    const howCloseToTarget = Math.round(
                                          ((minDoubleShots +
                                                minTripleShots +
                                                minQuadraShots) /
                                                8) *
                                                100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                  },
                  {
                        id: 15,
                        name: "speed is 2x, clear 15 lines with l shaped, z shaped tetrominos",

                        conditions: (game, player, destroyableRows) => {
                              const totalLinesCleared =
                                    calculateTotalLinesCleared(player);

                              if (totalLinesCleared >= 15) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              }
                              if (player.isGameOver) {
                                    const howCloseToTarget = Math.round(
                                          (totalLinesCleared / 15) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        initialize: (game) => {
                              game.randomTetrominoIndexes =
                                    game.generateNewTetrominoIndexes(
                                          ["l tetromino", "z tetromino"],
                                          500
                                    );
                              assignStartingTetrominoToAllPlayers(game);
                              game.players[0].currentSpeed = 15;
                              game.players[0].previouSpeed = 15;
                        },
                  },
                  {
                        id: 16,
                        name: "speed is 1.5x, achieve a score of 15000",
                        conditions: (game, player, destroyableRows) => {
                              if (player.stats.score >= 15000) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (player.isGameOver) {
                                    const howCloseToTarget = Math.round(
                                          (player.stats.score / 15000) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {
                              game.players.forEach((player) => {
                                    player.currentSpeed = 20;
                                    player.previouSpeed = 20;
                              });
                        },
                  },
            ],
            hard: [
                  {
                        id: 17,
                        name: "board size reduced by 3 rows,3 columns, match the pattern of clearing lines (3x, 2x, 4x), resets to starting if you miss the order",
                        conditions: (game, player, destroyableRows) => {
                              if (
                                    game.singlePlayerMode.patternToMatch[
                                          player.patternMatching.currentIndex
                                    ] === destroyableRows.length
                              ) {
                                    player.patternMatching.currentIndex++;
                                    if (
                                          player.patternMatching
                                                .bestPerformance <
                                          player.patternMatching.currentIndex
                                    ) {
                                          player.patternMatching.bestPerformance =
                                                player.patternMatching.currentIndex;
                                    }
                              } else if (destroyableRows.length > 0) {
                                    player.patternMatching.currentIndex = 0;
                              }
                              if (
                                    player.patternMatching.currentIndex ===
                                    game.singlePlayerMode.patternToMatch.length
                              ) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (player.isGameOver) {
                                    const howCloseToTarget = Math.round(
                                          (player.patternMatching
                                                .bestPerformance /
                                                game.singlePlayerMode
                                                      .patternToMatch.length) *
                                                100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {
                              game.singlePlayerMode.patternToMatch = [3, 2, 4];
                              game.boardColumns = 7;
                              game.boardRows = 17;
                              game.players[0].boardMatrix =
                                    createPlayerBoardMatrix(17, 7);
                              game.CoordinatesAndColorsOfTetrominos =
                                    tetrominoes(7);
                              assignStartingTetrominoToAllPlayers(game);
                        },
                  },
                  {
                        id: 18,
                        name: "speed is 1.5x, 2 life savers, achieve a score of 30000.",
                        conditions: (game, player, destroyableRows) => {
                              if (player.stats.score >= 30000) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (player.isGameOver) {
                                    const howCloseToTarget = Math.round(
                                          (player.stats.score / 30000) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {
                              game.players.forEach((player) => {
                                    player.currentSpeed = 20;
                                    player.previouSpeed = 20;
                                    player.lifeSaverCount = 2;
                              });
                        },
                  },
                  {
                        id: 19,
                        name: "clear 45 lines in 5 minutes",
                        conditions: (game, player, destroyableRows) => {
                              const totalLinesCleared =
                                    calculateTotalLinesCleared(player);

                              if (totalLinesCleared >= 45) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (
                                    player.isGameOver ||
                                    player.time >= 300
                              ) {
                                    const howCloseToTarget = Math.round(
                                          (totalLinesCleared / 45) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {},
                  },
                  {
                        id: 20,
                        name: "speed is 1.5x, 2 life savers, achieve 8 4x shots.",
                        conditions: (game, player) => {
                              if (player.stats.quadraShots >= 8) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (player.isGameOver) {
                                    const howCloseToTarget = Math.round(
                                          (player.stats.quadraShots / 8) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {
                              game.players.forEach((player) => {
                                    player.currentSpeed = 20;
                                    player.previouSpeed = 20;
                                    player.lifeSaverCount = 2;
                              });
                        },
                  },
                  {
                        id: 21,
                        name: "speed is 3x,board column reduced by 1, survive for 5 minutes without losing",
                        conditions: (game, player, destroyableRows) => {
                              if (player.time >= 300) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (player.isGameOver) {
                                    const howCloseToTarget = Math.round(
                                          (player.time / 300) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {
                              game.players.forEach((player) => {
                                    player.currentSpeed = 10;
                                    player.previouSpeed = 10;
                              });

                              game.boardColumns = 9;

                              game.players[0].boardMatrix =
                                    createPlayerBoardMatrix(20, 9);
                        },
                  },
                  {
                        id: 22,
                        name: "clear 20 lines using only l-shaped,z shaped tetrominos",
                        conditions: (game, player) => {
                              const totalLinesCleared =
                                    calculateTotalLinesCleared(player);

                              if (totalLinesCleared >= 20) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (player.isGameOver) {
                                    const howCloseToTarget = Math.round(
                                          (totalLinesCleared / 20) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {
                              game.randomTetrominoIndexes =
                                    game.generateNewTetrominoIndexes(
                                          ["l tetromino", "z tetromino"],
                                          500
                                    );
                              assignStartingTetrominoToAllPlayers(game);
                        },
                  },
                  {
                        id: 23,
                        name: "match the pattern of clearing lines (3x, 3x, 3x), resets to starting if you miss the order",
                        conditions: (game, player, destroyableRows) => {
                              if (
                                    game.singlePlayerMode.patternToMatch[
                                          player.patternMatching.currentIndex
                                    ] === destroyableRows.length
                              ) {
                                    player.patternMatching.currentIndex++;
                                    if (
                                          player.patternMatching
                                                .bestPerformance <
                                          player.patternMatching.currentIndex
                                    ) {
                                          player.patternMatching.bestPerformance =
                                                player.patternMatching.currentIndex;
                                    }
                              } else if (destroyableRows.length > 0) {
                                    player.patternMatching.currentIndex = 0;
                              }
                              if (
                                    player.patternMatching.currentIndex ===
                                    game.singlePlayerMode.patternToMatch.length
                              ) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (player.isGameOver) {
                                    const howCloseToTarget = Math.round(
                                          (player.patternMatching
                                                .bestPerformance /
                                                game.singlePlayerMode
                                                      .patternToMatch.length) *
                                                100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {
                              game.singlePlayerMode.patternToMatch = [3, 3, 3];
                        },
                  },
                  {
                        id: 24,
                        name: "speed is 3x, 2 life savers, achieve a score of 15000",
                        conditions: (game, player, destroyableRows) => {
                              if (player.stats.score >= 15000) {
                                    singlePlayerChallengeCompleted(
                                          game,
                                          player
                                    );
                              } else if (player.isGameOver) {
                                    const howCloseToTarget = Math.round(
                                          (player.stats.score / 15000) * 100
                                    );
                                    singlePlayerChallengeLost(
                                          game,
                                          player,
                                          howCloseToTarget
                                    );
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {
                              game.players.forEach((player) => {
                                    player.currentSpeed = 10;
                                    player.previouSpeed = 10;
                                    player.lifeSaverCount = 2;
                              });
                        },
                  },
            ],
            others: [
                  {
                        conditions: (game, player) => {
                              if (player.time === 20) {
                                    player.isGameOver = true;
                                    game.sounds.gameOverSound.play();
                                    game.isGameOver = true;
                                    game.renderGameResult(true);
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {},
                  },
            ],
      },
      splitScreen: {
            others: [
                  {
                        name: "speed increases gradually 1.5x at 90s, 2x at 3min, 3x at 6min, 6x at 8min,score as much as you can, whoever scores the highest wins the game.",
                        conditions: (game, player) => {
                              if (player.time === 90) {
                                    player.currentSpeed = 20;
                                    player.previouSpeed = 20;
                              }
                              if (player.time === 180) {
                                    player.currentSpeed = 15;
                                    player.previouSpeed = 15;
                              }
                              if (player.time === 360) {
                                    player.currentSpeed = 10;
                                    player.previouSpeed = 10;
                              }
                              if (player.time === 480) {
                                    player.currentSpeed = 5;
                                    player.previouSpeed = 5;
                              }
                              if (player.isGameOver) {
                                    game.splitScreenMode
                                          .numberOfPlayersFinished++;
                              }
                              if (
                                    player.isGameOver ||
                                    game.splitScreenMode
                                          .numberOfPlayersFinished ===
                                          game.splitScreenMode
                                                .maxNumberOfPlayers -
                                                1
                              ) {
                                    game.checkGameOver();
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {},
                  },

                  {
                        name: "speed increases gradually 1.5x at 90s, 2x at 3min, 3x at 6min, 6x at 8min, whoever survives till the end wins the game.",
                        conditions: (game, player) => {
                              if (player.time === 90) {
                                    player.currentSpeed = 20;
                                    player.previouSpeed = 20;
                              }
                              if (player.time === 180) {
                                    player.currentSpeed = 15;
                                    player.previouSpeed = 15;
                              }
                              if (player.time === 360) {
                                    player.currentSpeed = 10;
                                    player.previouSpeed = 10;
                              }
                              if (player.time === 480) {
                                    player.currentSpeed = 5;
                                    player.previouSpeed = 5;
                              }
                              if (player.isGameOver) {
                                    game.splitScreenMode.gameOverPlayers.push(
                                          player
                                    );
                                    if (
                                          game.splitScreenMode.gameOverPlayers
                                                .length ===
                                          game.splitScreenMode
                                                .maxNumberOfPlayers -
                                                1
                                    ) {
                                          const lastPlayerStanding =
                                                game.players.filter(
                                                      (player) => {
                                                            return !player.isGameOver;
                                                      }
                                                );

                                          game.splitScreenMode.winners.push(
                                                lastPlayerStanding[0]
                                          );
                                          game.splitScreenMode.losers.push(
                                                ...game.splitScreenMode.gameOverPlayers.reverse()
                                          );

                                          game.sounds.winningSound.play();
                                          game.isGameOver = true;
                                          game.renderGameResult(true);
                                    }
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {},
                  },
                  {
                        name: "score as much as you can in 5 minutes, whoever scores the highest wins the game",
                        conditions: (game, player) => {
                              if (player.time >= 30) {
                                    player.isGameOver = true;
                              }
                              if (player.isGameOver) {
                                    game.splitScreenMode
                                          .numberOfPlayersFinished++;
                              }
                              if (
                                    player.isGameOver ||
                                    game.splitScreenMode
                                          .numberOfPlayersFinished ===
                                          game.splitScreenMode
                                                .maxNumberOfPlayers -
                                                1
                              ) {
                                    game.checkGameOver();
                              }
                        },
                        reset: (game) => {},
                        initialize: (game) => {},
                  },
            ],
      },
};

export const assignStartingTetrominoToAllPlayers = (game) => {
      const startingTetromino =
            game.CoordinatesAndColorsOfTetrominos[
                  game.randomTetrominoIndexes[0]
            ];
      game.players.forEach((player) => {
            player.currentTetromino = {
                  allCoordinates: deepCloneArray(
                        startingTetromino.allCoordinates
                  ),
                  colorClass: startingTetromino.colorClass,
            };
      });
};

export const defaultUserData = {
      revalidated: "true",
      stats: {
            singlePlayerMode: { easy: {}, medium: {}, hard: {} },
      },
      settings: {
            controls: {
                  controller: [
                        [
                              "hardDrop",
                              "rotate",
                              null,
                              null,
                              null,
                              null,
                              null,
                              "lifeSaver",
                              null,
                              null,
                              null,
                              null,
                              null,
                              "ArrowDown",
                              "ArrowLeft",
                              "ArrowRight",
                              null,
                        ],
                        [
                              "hardDrop",
                              "rotate",
                              null,
                              null,
                              null,
                              null,
                              null,
                              "lifeSaver",
                              null,
                              null,
                              null,
                              null,
                              null,
                              "ArrowDown",
                              "ArrowLeft",
                              "ArrowRight",
                              null,
                        ],
                  ],
                  keyBoard: {
                        ArrowDown: {
                              playerNumber: "0",
                              bindingValue: "ArrowDown",
                        },
                        ArrowLeft: {
                              playerNumber: "0",
                              bindingValue: "ArrowLeft",
                        },
                        ArrowRight: {
                              playerNumber: "0",
                              bindingValue: "ArrowRight",
                        },
                        " ": {
                              playerNumber: null,
                              bindingValue: null,
                        },
                        a: {
                              playerNumber: "1",
                              bindingValue: "lifeSaver",
                        },
                        b: {
                              playerNumber: null,
                              bindingValue: null,
                        },
                        c: {
                              playerNumber: null,
                              bindingValue: null,
                        },
                        d: {
                              playerNumber: "1",
                              bindingValue: "hardDrop",
                        },
                        e: {
                              playerNumber: "0",
                              bindingValue: "hardDrop",
                        },
                        f: {
                              playerNumber: null,
                              bindingValue: null,
                        },
                        g: {
                              playerNumber: null,
                              bindingValue: null,
                        },
                        h: {
                              playerNumber: null,
                              bindingValue: null,
                        },
                        i: {
                              playerNumber: null,
                              bindingValue: null,
                        },
                        j: {
                              playerNumber: "1",
                              bindingValue: "ArrowLeft",
                        },
                        k: {
                              playerNumber: "1",
                              bindingValue: "ArrowDown",
                        },
                        l: {
                              playerNumber: "1",
                              bindingValue: "ArrowRight",
                        },
                        m: {
                              playerNumber: null,
                              bindingValue: null,
                        },
                        n: {
                              playerNumber: null,
                              bindingValue: null,
                        },
                        o: {
                              playerNumber: null,
                              bindingValue: null,
                        },
                        p: {
                              playerNumber: null,
                              bindingValue: null,
                        },
                        q: {
                              playerNumber: "0",
                              bindingValue: "lifeSaver",
                        },
                        r: {
                              playerNumber: null,
                              bindingValue: null,
                        },
                        s: {
                              playerNumber: "1",
                              bindingValue: "rotate",
                        },
                        t: {
                              playerNumber: null,
                              bindingValue: null,
                        },
                        u: {
                              playerNumber: null,
                              bindingValue: null,
                        },
                        v: {
                              playerNumber: null,
                              bindingValue: null,
                        },
                        w: {
                              playerNumber: "0",
                              bindingValue: "rotate",
                        },
                        x: {
                              playerNumber: null,
                              bindingValue: null,
                        },
                        y: {
                              playerNumber: null,
                              bindingValue: null,
                        },
                        z: {
                              playerNumber: null,
                              bindingValue: null,
                        },
                        Control: {
                              0: {
                                    playerNumber: null,
                                    bindingValue: null,
                              },
                              1: {
                                    playerNumber: null,
                                    bindingValue: null,
                              },
                        },
                        Shift: {
                              0: {
                                    playerNumber: null,
                                    bindingValue: null,
                              },
                              1: {
                                    playerNumber: null,
                                    bindingValue: null,
                              },
                        },
                  },
                  touch: [
                        "hardDrop",
                        "rotate",
                        "lifeSaver",
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "ArrowDown",
                        "ArrowLeft",
                        "ArrowRight",
                        null,
                  ],
            },
            skin: "theme-0",
            showTouchControls: "no",
      },
};

export const playSound = (game, type) => {
      if (type === "navigation") {
            game.sounds.navigationSound.play();
      } else if (type === "click") {
            game.sounds.clickSound.play();
      }
};

export const focusOnMouseMove = (event, focusableElements, game, playSound) => {
      focusableElements?.elements?.forEach((element, index) => {
            if (event.target === element && index !== focusableElements.index) {
                  playSound(game, "navigation");
                  element.focus();
                  focusableElements.index = index;
            }
      });
};

export const navigationUserInputHandler = (
      event,
      focusableElements,
      game,
      goBackCallBack,
      playSound
) => {
      if (event.stopPropagation) {
            event.stopPropagation();
      }

      if (event.preventDefault) {
            event.preventDefault();
      }

      if (event.key === "ArrowDown") {
            if (
                  focusableElements.index ===
                  focusableElements.elements.length - 1
            ) {
                  focusableElements.index = 0;
            } else {
                  focusableElements.index++;
            }
            if (playSound) {
                  playSound(game, "navigation");
            }
            focusableElements.elements[focusableElements.index].focus();
      } else if (event.key === "ArrowUp") {
            if (focusableElements.index === 0) {
                  return;
            } else {
                  if (playSound) {
                        playSound(game, "navigation");
                  }
                  focusableElements.index--;
                  focusableElements.elements[focusableElements.index].focus();
            }
      } else if (event.key === "Enter") {
            focusableElements.elements[focusableElements.index].click();
      } else if (event.key === "Back" || event.key === "Backspace") {
            if (goBackCallBack) {
                  if (playSound) {
                        playSound(game, "click");
                  }
                  goBackCallBack();
            }
      }
};

export const calculateTotalLinesCleared = (player) => {
      return (
            player.stats.singleShots +
            player.stats.doubleShots * 2 +
            player.stats.tripleShots * 3 +
            player.stats.quadraShots * 4
      );
};

export const saveSomeData = (object) => {
      object.forEach((element) => {
            if (element.done === "yes") {
                  element.howCloseToTarget = 100;
            } else if (
                  element.done === "no" &&
                  element.howCloseToTarget === undefined
            ) {
                  element.howCloseToTarget = 0;
            }
      });
};
