import { defer } from "react-router";
export const getUserSavedData = async () => {
      try {
            const response = localStorage.getItem("userDataOne");

            const data = await JSON.parse(response);

            return { status: "success", payload: data };
      } catch (error) {
            return { status: "error", message: error.message };
      }
};
export const gamePageLoader = () => {
      return defer({
            userSavedDataLoader: getUserSavedData(),
      });
};
