import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
      faArrowRight,
      faA,
      faB,
      faX,
      faY,
} from "@fortawesome/free-solid-svg-icons";
import { finalInput } from "../../utilities/utilities";
import styles from "./touchControls.module.css";

export const TouchControls = ({ game, player }) => {
      return (
            <section className={styles["touch-controls-section"]}>
                  <div className={styles["controls"]}>
                        <FontAwesomeIcon
                              icon={faY}
                              style={{
                                    backgroundColor: "yellow",
                              }}
                              className={styles["button"]}
                        ></FontAwesomeIcon>

                        <div className={styles["middle-row"]}>
                              <FontAwesomeIcon
                                    icon={faX}
                                    style={{
                                          backgroundColor: "blue",
                                    }}
                                    className={styles["button"]}
                                    onClick={() => {
                                          if (!player.destroyInAction) {
                                                finalInput(
                                                      game.touch
                                                            .defaultKeyBindings[2],
                                                      player,
                                                      game.sounds,
                                                      null,
                                                      game
                                                );
                                          }
                                    }}
                              ></FontAwesomeIcon>
                              <FontAwesomeIcon
                                    icon={faB}
                                    style={{
                                          backgroundColor: "red",
                                    }}
                                    className={styles["button"]}
                                    onClick={(event) => {
                                          if (!player.destroyInAction) {
                                                finalInput(
                                                      game.touch
                                                            .defaultKeyBindings[1],
                                                      player,
                                                      game.sounds,
                                                      null,
                                                      game
                                                );
                                          }
                                    }}
                              ></FontAwesomeIcon>
                        </div>

                        <FontAwesomeIcon
                              icon={faA}
                              style={{
                                    backgroundColor: "green",
                              }}
                              className={styles["button"]}
                              onClick={() => {
                                    if (!player.destroyInAction) {
                                          finalInput(
                                                game.touch
                                                      .defaultKeyBindings[0],
                                                player,
                                                game.sounds,
                                                null,
                                                game
                                          );
                                    }
                              }}
                        ></FontAwesomeIcon>
                  </div>
                  <div className={styles["controls"]}>
                        <FontAwesomeIcon
                              icon={faArrowRight}
                              style={{
                                    backgroundColor: "white",
                                    transform: "rotate(270deg)",
                              }}
                              className={styles["button"]}
                              onClick={() => {
                                    if (!player.destroyInAction) {
                                          finalInput(
                                                game.touch
                                                      .defaultKeyBindings[12],
                                                player,
                                                game.sounds,
                                                null,
                                                game
                                          );
                                    }
                              }}
                        ></FontAwesomeIcon>

                        <div className={styles["middle-row"]}>
                              <FontAwesomeIcon
                                    icon={faArrowRight}
                                    style={{
                                          backgroundColor: "white",
                                          transform: "rotate(180deg)",
                                    }}
                                    className={styles["button"]}
                                    onClick={() => {
                                          if (!player.destroyInAction) {
                                                finalInput(
                                                      game.touch
                                                            .defaultKeyBindings[14],
                                                      player,
                                                      game.sounds,
                                                      null,
                                                      game
                                                );
                                          }
                                    }}
                              ></FontAwesomeIcon>
                              <FontAwesomeIcon
                                    icon={faArrowRight}
                                    style={{
                                          backgroundColor: "white",
                                    }}
                                    className={styles["button"]}
                                    onClick={() => {
                                          if (!player.destroyInAction) {
                                                finalInput(
                                                      game.touch
                                                            .defaultKeyBindings[15],
                                                      player,
                                                      game.sounds,
                                                      null,
                                                      game
                                                );
                                          }
                                    }}
                              ></FontAwesomeIcon>
                        </div>

                        <FontAwesomeIcon
                              icon={faArrowRight}
                              style={{
                                    backgroundColor: "white",
                                    transform: "rotate(90deg)",
                              }}
                              className={styles["button"]}
                              onClick={() => {
                                    if (!player.destroyInAction) {
                                          finalInput(
                                                game.touch
                                                      .defaultKeyBindings[13],
                                                player,
                                                game.sounds,
                                                null,
                                                game
                                          );
                                    }
                              }}
                        ></FontAwesomeIcon>
                  </div>
            </section>
      );
};
