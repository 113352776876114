import { useEffect, useState, useMemo, useContext } from "react";
import styles from "./challenges.module.css";
import { GoBackButton } from "../../../components/buttons/goBack";
import { CardOne } from "../../../components/cards/cardOne";
import { themeContext } from "../../../context/theme";
import { Option } from "../../../components/options/option";
import {
      focusOnMouseMove,
      navigationUserInputHandler,
      navigationGamepadLoop,
      playSound,
      challenges,
} from "../../../utilities/utilities";

import { DonutProgress } from "../donutProgress";
import { SinglePlayerStats } from "./singlePlayerStats";
export const Challenges = ({
      setShowMenuOverlay,
      setShowGameStartTimer,
      setShowSinglePlayer,
      game,
}) => {
      const gameModesCloseButtonClickHandler = () => {
            setShowSinglePlayer(false);
      };
      const easyClickHandler = (event) => {
            event.stopPropagation();

            const value = event.target.getAttribute("data-value");
            const difficulty =
                  event.currentTarget.getAttribute("data-difficulty");

            if (!value || !difficulty) {
                  return;
            }

            game.reset();

            game.singlePlayerMode.challenge =
                  challenges["singlePlayer"][difficulty][value];

            game.singlePlayerMode.difficulty = difficulty;

            game.singlePlayerMode.challenges =
                  challenges["singlePlayer"][difficulty];

            game.singlePlayerMode.challengeIndex = +value;

            challenges["singlePlayer"][difficulty][value].initialize(game);

            game.sounds.clickSound.play();

            setShowMenuOverlay(false);
            setShowGameStartTimer(true);
      };

      const { theme, setTheme } = useContext(themeContext);

      const [startGamePadLoop, setStartGamePadLoop] = useState(true);
      const gamepadLoopState = useMemo(() => {
            return { run: true };
      }, []);
      const focusableElements = useMemo(() => {
            return { elements: null, index: 0 };
      }, []);

      useEffect(() => {
            if (gamepadLoopState.run) {
                  navigationGamepadLoop(
                        game,
                        (event) => {
                              navigationUserInputHandler(
                                    event,
                                    focusableElements,
                                    game,
                                    () => {
                                          gamepadLoopState.run = false;
                                          setShowSinglePlayer(false);
                                    },
                                    playSound
                              );
                        },
                        gamepadLoopState
                  );
            }
      }, [startGamePadLoop]);

      useEffect(() => {
            focusableElements.elements =
                  document.querySelectorAll("[tabindex='4']");
      }, []);

      useEffect(() => {
            focusableElements.elements[focusableElements.index]?.focus();
      }, [gamepadLoopState.run]);

      useEffect(() => {
            return () => {
                  gamepadLoopState.run = false;
            };
      }, []);

      return (
            <CardOne
                  customTag="section"
                  attributes={{
                        className: styles["game-modes-section"],
                        onKeyDown: (event) => {
                              navigationUserInputHandler(
                                    event,
                                    focusableElements,
                                    game,
                                    () => {
                                          gamepadLoopState.run = false;
                                          setShowSinglePlayer(false);
                                    },
                                    playSound
                              );
                        },
                        onMouseMove: (event) => {
                              if (gamepadLoopState.run) {
                                    focusOnMouseMove(
                                          event,
                                          focusableElements,
                                          game,
                                          playSound
                                    );
                              }
                        },
                        tabIndex: -1,
                  }}
            >
                  <GoBackButton
                        onClickHandler={gameModesCloseButtonClickHandler}
                        game={game}
                  ></GoBackButton>
                  <div className={styles["game-modes"]}>
                        <h1 className={styles["game-modes-heading"]}>
                              Single player
                        </h1>

                        <SinglePlayerStats game={game}></SinglePlayerStats>

                        <div className={styles["setting-container"]}>
                              <div className={styles["challenges-sub-type"]}>
                                    <h2 className={styles["sub-type-heading"]}>
                                          Easy
                                    </h2>
                                    <div
                                          className={styles["challenges"]}
                                          onClick={easyClickHandler}
                                          data-difficulty="easy"
                                    >
                                          {challenges["singlePlayer"][
                                                "easy"
                                          ].map((challenge, index) => {
                                                const challengeStats =
                                                      game.userSavedData.stats
                                                            .singlePlayerMode
                                                            .easy[challenge.id];
                                                return (
                                                      <Option
                                                            attributes={{
                                                                  tabIndex: 4,
                                                                  "data-value":
                                                                        index,
                                                            }}
                                                            key={index}
                                                            customClass={
                                                                  styles[
                                                                        "option"
                                                                  ] +
                                                                  " " +
                                                                  styles[
                                                                        "option-" +
                                                                              theme
                                                                  ]
                                                            }
                                                      >
                                                            <div
                                                                  className={
                                                                        styles[
                                                                              "challenge-number"
                                                                        ]
                                                                  }
                                                            >
                                                                  {index + 1}.
                                                            </div>
                                                            <div
                                                                  className={
                                                                        styles[
                                                                              "challenge-name"
                                                                        ]
                                                                  }
                                                                  data-value={
                                                                        index
                                                                  }
                                                            >
                                                                  {
                                                                        challenge.name
                                                                  }
                                                            </div>

                                                            <DonutProgress
                                                                  challengeStats={
                                                                        challengeStats
                                                                  }
                                                            ></DonutProgress>
                                                      </Option>
                                                );
                                          })}
                                    </div>
                              </div>
                              <div className={styles["challenges-sub-type"]}>
                                    <h2 className={styles["sub-type-heading"]}>
                                          Medium
                                    </h2>
                                    <div
                                          className={styles["challenges"]}
                                          onClick={easyClickHandler}
                                          data-difficulty="medium"
                                    >
                                          {challenges["singlePlayer"][
                                                "medium"
                                          ].map((challenge, index) => {
                                                const challengeStats =
                                                      game.userSavedData.stats
                                                            .singlePlayerMode
                                                            .medium[
                                                            challenge.id
                                                      ];
                                                return (
                                                      <Option
                                                            attributes={{
                                                                  tabIndex: 4,
                                                                  "data-value":
                                                                        index,
                                                            }}
                                                            key={index}
                                                            customClass={
                                                                  styles[
                                                                        "option"
                                                                  ] +
                                                                  " " +
                                                                  styles[
                                                                        "option-" +
                                                                              theme
                                                                  ]
                                                            }
                                                      >
                                                            <div
                                                                  className={
                                                                        styles[
                                                                              "challenge-number"
                                                                        ]
                                                                  }
                                                            >
                                                                  {index + 1}.
                                                            </div>
                                                            <div
                                                                  className={
                                                                        styles[
                                                                              "challenge-name"
                                                                        ]
                                                                  }
                                                                  data-value={
                                                                        index
                                                                  }
                                                            >
                                                                  {
                                                                        challenge.name
                                                                  }
                                                            </div>

                                                            <DonutProgress
                                                                  challengeStats={
                                                                        challengeStats
                                                                  }
                                                            ></DonutProgress>
                                                      </Option>
                                                );
                                          })}
                                    </div>
                              </div>

                              <div className={styles["challenges-sub-type"]}>
                                    <h2 className={styles["sub-type-heading"]}>
                                          hard
                                    </h2>
                                    <div
                                          className={styles["challenges"]}
                                          onClick={easyClickHandler}
                                          data-difficulty="hard"
                                    >
                                          {challenges["singlePlayer"][
                                                "hard"
                                          ].map((challenge, index) => {
                                                const challengeStats =
                                                      game.userSavedData.stats
                                                            .singlePlayerMode
                                                            .hard[challenge.id];
                                                return (
                                                      <Option
                                                            attributes={{
                                                                  tabIndex: 4,
                                                                  "data-value":
                                                                        index,
                                                            }}
                                                            key={index}
                                                            customClass={
                                                                  styles[
                                                                        "option"
                                                                  ] +
                                                                  " " +
                                                                  styles[
                                                                        "option-" +
                                                                              theme
                                                                  ]
                                                            }
                                                      >
                                                            <div
                                                                  className={
                                                                        styles[
                                                                              "challenge-number"
                                                                        ]
                                                                  }
                                                            >
                                                                  {index + 1}.
                                                            </div>
                                                            <div
                                                                  className={
                                                                        styles[
                                                                              "challenge-name"
                                                                        ]
                                                                  }
                                                                  data-value={
                                                                        index
                                                                  }
                                                            >
                                                                  {
                                                                        challenge.name
                                                                  }
                                                            </div>

                                                            <DonutProgress
                                                                  challengeStats={
                                                                        challengeStats
                                                                  }
                                                            ></DonutProgress>
                                                      </Option>
                                                );
                                          })}
                                    </div>
                              </div>
                        </div>
                  </div>
            </CardOne>
      );
};
