import gamePadImage from "../../assets/images/pngwing.com.png";
import styles from "./controller.module.css";
import { ControllerSelect } from "./controllerSelect";
import { CardTwo } from "../../components/cards/cardTwo";
import { useState } from "react";
export const Controller = ({
      joystickIndex,
      previousGamepadLoop,
      joystick,
      game,
}) => {
      const [showLoader, setShowLoader] = useState(true);
      return (
            <CardTwo customClass={styles["connected-controller"]}>
                  <div className={styles["container"]}>
                        <img
                              src={gamePadImage}
                              alt="gamepad"
                              className={styles["gamepad-image"]}
                              style={{
                                    height: showLoader ? 0 : "initial",
                              }}
                              onLoad={() => {
                                    setShowLoader(false);
                              }}
                              onError={() => {
                                    setShowLoader(false);
                              }}
                        ></img>
                        {showLoader ? (
                              <div className={styles["image-loader"]}>
                                    loading...
                              </div>
                        ) : null}
                        <div>Player-{joystickIndex}</div>
                  </div>
                  {joystick ? (
                        <div>
                              <ControllerSelect
                                    previousGamepadLoop={previousGamepadLoop}
                                    bindingValue="ArrowDown"
                                    joystickIndex={joystickIndex}
                                    joystick={joystick}
                                    game={game}
                              ></ControllerSelect>
                              <ControllerSelect
                                    previousGamepadLoop={previousGamepadLoop}
                                    bindingValue="ArrowRight"
                                    joystickIndex={joystickIndex}
                                    joystick={joystick}
                                    game={game}
                              ></ControllerSelect>
                              <ControllerSelect
                                    previousGamepadLoop={previousGamepadLoop}
                                    bindingValue="ArrowLeft"
                                    joystickIndex={joystickIndex}
                                    joystick={joystick}
                                    game={game}
                              ></ControllerSelect>
                              <ControllerSelect
                                    previousGamepadLoop={previousGamepadLoop}
                                    bindingValue="rotate"
                                    joystickIndex={joystickIndex}
                                    joystick={joystick}
                                    game={game}
                              ></ControllerSelect>
                              <ControllerSelect
                                    previousGamepadLoop={previousGamepadLoop}
                                    bindingValue="lifeSaver"
                                    joystickIndex={joystickIndex}
                                    joystick={joystick}
                                    game={game}
                              ></ControllerSelect>
                              <ControllerSelect
                                    previousGamepadLoop={previousGamepadLoop}
                                    bindingValue="hardDrop"
                                    joystickIndex={joystickIndex}
                                    joystick={joystick}
                                    game={game}
                              ></ControllerSelect>
                        </div>
                  ) : (
                        <div className={styles["gamepad-not-connected"]}>
                              not connected
                        </div>
                  )}
            </CardTwo>
      );
};
