import { useEffect, useState, useMemo, useRef, useContext } from "react";
import styles from "./guide.module.css";
import { CardOne } from "../../../components/cards/cardOne";
import { CardTwo } from "../../../components/cards/cardTwo";
import { GoBackButton } from "../../../components/buttons/goBack";
import { navigationGamepadLoop } from "../../../utilities/utilities";
import { themeContext } from "../../../context/theme";

export const Guide = ({ setShowGuide, game }) => {
      const [startGamePadLoop, setStartGamePadLoop] = useState(true);
      const { theme } = useContext(themeContext);
      const guideRef = useRef();
      const gamepadLoopState = useMemo(() => {
            return { run: true };
      }, []);
      const gameModesCloseButtonClickHandler = () => {
            setShowGuide(false);
      };

      const controllerSettingsOverlayKeyDownHandler = (event) => {
            if (event.stopPropagation) {
                  event.stopPropagation();
                  if (event.key !== "Backspace") {
                        return;
                  }
            }
            if (event.key === "ArrowDown") {
                  if (guideRef.current) {
                        guideRef.current.scrollBy({
                              top: 50,
                        });
                  }
            } else if (event.key === "ArrowUp") {
                  if (guideRef.current) {
                        guideRef.current.scrollBy({
                              top: -50,
                        });
                  }
            } else if (event.key === "Enter") {
            } else if (event.key === "Back" || event.key === "Backspace") {
                  game.sounds.clickSound.play();
                  gamepadLoopState.run = false;
                  setShowGuide(false);
            }
      };

      useEffect(() => {
            if (gamepadLoopState.run) {
                  navigationGamepadLoop(
                        game,
                        controllerSettingsOverlayKeyDownHandler,
                        gamepadLoopState
                  );
            }
      }, [startGamePadLoop]);

      useEffect(() => {
            guideRef.current.focus();
      }, []);

      useEffect(() => {
            return () => {
                  gamepadLoopState.run = false;
            };
      }, []);
      return (
            <CardOne
                  customTag="section"
                  attributes={{
                        className: styles["guide-section"],
                        onKeyDown: controllerSettingsOverlayKeyDownHandler,
                        tabIndex: -1,
                        ref: guideRef,
                  }}
            >
                  <GoBackButton
                        onClickHandler={gameModesCloseButtonClickHandler}
                        game={game}
                  ></GoBackButton>
                  <div className={styles["guide"]}>
                        <h1 className={styles["guide-heading"]}>Guide</h1>
                        <CardTwo customClass={styles["container"]}>
                              <h2
                                    className={
                                          styles["h2-heading"] +
                                          " " +
                                          styles["h2-heading-" + theme]
                                    }
                              >
                                    Introduction
                              </h2>
                              <p className={styles["p1"]}>
                                    Dive into the world of our challenging,
                                    intuitive, and fun web-based Tetris game.
                                    Designed with ease of use,clean user
                                    interface ,split screen,controller support
                                    and many more. This classic favorite has
                                    been given a fresh, contemporary twist for
                                    your enjoyment. Whether you're a seasoned
                                    Tetris pro or a newcomer to the game, this
                                    guide is here to help you make the most of
                                    your experience. Let's explore the exciting
                                    features and navigate through the
                                    captivating world of Tetris!
                              </p>
                              <h2
                                    className={
                                          styles["h2-heading"] +
                                          " " +
                                          styles["h2-heading-" + theme]
                                    }
                              >
                                    Game Modes
                              </h2>
                              <p className={styles["p1"]}>
                                    Get ready for an exhilarating Tetris
                                    experience with two distinct game modes,
                                    each offering its own set of challenges and
                                    excitement.
                              </p>
                              <ul>
                                    <li>
                                          <b>Single Player:</b> embark on a solo
                                          journey to complete all the challenges
                                          of different difficulties and show
                                          your true skills.
                                    </li>
                                    <li>
                                          <b>split screen (2 Players):</b>
                                          invite a friend to join the fun!
                                          Experience the thrill of competitive
                                          Tetris with two players on the same
                                          screen, side by side. It's a test of
                                          skill and strategy as you aim to
                                          outlast and outscore your opponent.
                                          This mode is not supported in mobiles
                                          and small screens.
                                    </li>
                              </ul>

                              <h2
                                    className={
                                          styles["h2-heading"] +
                                          " " +
                                          styles["h2-heading-" + theme]
                                    }
                              >
                                    In game controls
                              </h2>
                              <ul>
                                    <li>controller only support</li>
                                    <li>controller and keyboard support</li>
                                    <li> keyboard only support</li>
                                    <li>upto 2 controllers are supported</li>
                                    <li>
                                          you can change key bindings in the
                                          controls section.
                                    </li>
                                    <li>
                                          vibration support with compatible
                                          controllers. We tested using red gear
                                          pro wireless, cosmic byte nebula
                                          wireless.
                                    </li>
                                    <li>
                                          use Escape key on keyboard to pause
                                          the game
                                    </li>
                                    <li>
                                          use start button(usually named as
                                          start and also known as button number
                                          9) on controller to pause the game.
                                    </li>
                                    <li>
                                          <b>life-saver:</b> It clears all the
                                          blocks of the top 5 rows. You can use
                                          it when you are closer to the death or
                                          whenever you may want to.
                                    </li>
                                    <li>
                                          {" "}
                                          <a
                                                className={
                                                      styles[
                                                            "controller-layout"
                                                      ]
                                                }
                                                href="https://arunmichaeldsouza.com/img/blogs/web-platform's-hidden-gems---gamepad-api/7.png"
                                          >
                                                click here for button numbers
                                          </a>
                                    </li>
                              </ul>
                              <h2
                                    className={
                                          styles["h2-heading"] +
                                          " " +
                                          styles["h2-heading-" + theme]
                                    }
                              >
                                    Naviagtion controls
                              </h2>
                              <p className={styles["p1"]}>
                                    Another big feature of our game is that you
                                    get full contoller, keyboard, mouse and
                                    touch support for navigating the whole game.
                              </p>
                              <ul>
                                    <li>
                                          <b>controller:</b>
                                    </li>

                                    <ul>
                                          <li>
                                                use d-pad up and d-pad down
                                                buttons to navigate through
                                                options, to scroll guide page
                                          </li>
                                          <li>
                                                use X button to select an option
                                          </li>
                                          <li>
                                                use B button to go back to
                                                previous options
                                          </li>
                                    </ul>
                              </ul>
                              <ul>
                                    <li>
                                          <b className={styles["h3-heading"]}>
                                                keyboard:
                                          </b>
                                    </li>

                                    <ul>
                                          <li>
                                                use Arrow up and Arrow down keys
                                                to navigate through options,to
                                                scroll guide page
                                          </li>
                                          <li>
                                                use Enter key to select an
                                                option
                                          </li>
                                          <li>
                                                use backspace key to go back to
                                                previous options
                                          </li>
                                    </ul>
                              </ul>

                              <h2
                                    className={
                                          styles["h2-heading"] +
                                          " " +
                                          styles["h2-heading-" + theme]
                                    }
                              >
                                    Scoring
                              </h2>

                              <ul>
                                    <li>1x shot: 100</li>
                                    <li>2x shot: 400</li>
                                    <li>3x shot: 900</li>
                                    <li>4x shot: 1600</li>
                              </ul>

                              <h2
                                    className={
                                          styles["h2-heading"] +
                                          " " +
                                          styles["h2-heading-" + theme]
                                    }
                              >
                                    Skins
                              </h2>
                              <p className={styles["p1"]}>
                                    Explore our collection of carefully crafted
                                    skins, each designed to elevate your gaming
                                    experience. With different color schemes and
                                    minimalist designs, there's a skin for every
                                    taste. Choose the one that reflects your
                                    style and adds an extra layer of enjoyment
                                    to your Tetris sessions.Our skins are not
                                    just about aesthetics—they're designed for
                                    optimal visual appeal. Crisp graphics,
                                    seamless animations, and attention to detail
                                    create a visually engaging environment.
                              </p>

                              <ul>
                                    <li>Blue</li>
                                    <li>Red</li>
                                    <li>Green</li>
                                    <li>Violet</li>
                                    <li>Yellow</li>
                              </ul>

                              <h2
                                    className={
                                          styles["h2-heading"] +
                                          " " +
                                          styles["h2-heading-" + theme]
                                    }
                              >
                                    Requirements
                              </h2>
                              <ul>
                                    <li>
                                          For performance we tested using
                                          snapdragon 675(mobile), intel
                                          i3-1115G4(laptop). Gets close to 60fps
                                          on both.
                                    </li>
                                    <li>
                                          single player is playable on mobiles
                                          as well.
                                    </li>
                                    <li>
                                          on screen controls are supported only
                                          for singleplayer.
                                    </li>
                                    <li>
                                          split screen is not playable on
                                          mobiles.It adjusts to every screen but
                                          it looks very small on very small
                                          screens.
                                    </li>
                              </ul>

                              <h2
                                    className={
                                          styles["h2-heading"] +
                                          " " +
                                          styles["h2-heading-" + theme]
                                    }
                              >
                                    Stats
                              </h2>

                              <ul>
                                    <li>
                                          Each challenge is given a rating(out
                                          of 100) based on how close you get to
                                          the target. The closer you are the
                                          higher the rating for that challenge.
                                          Your best rating is taken into the
                                          account for that challenge.
                                    </li>
                                    <li>
                                          Your are given overall rating which is
                                          the average of the all the best
                                          ratings of all the attempted
                                          challenges.
                                    </li>
                              </ul>
                        </CardTwo>
                  </div>
            </CardOne>
      );
};
