import { Await, useLoaderData } from "react-router";
import { Suspense } from "react";
import { LoaderPage } from "./LoaderPage";

export const AwaitGamePage = () => {
      const { userSavedDataLoader } = useLoaderData();

      return (
            <Suspense fallback={<div>loading...</div>}>
                  <Await resolve={userSavedDataLoader}>
                        <LoaderPage></LoaderPage>
                  </Await>
            </Suspense>
      );
};
