export const Loader = (props) => (
      <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            width={50}
            height={50}
            style={{
                  shapeRendering: "auto",
                  display: "block",
                  background: "transparent",
            }}
      >
            <g>
                  <circle
                        cx={50}
                        cy={50}
                        r={32}
                        strokeWidth={7}
                        stroke="#ffffff"
                        strokeDasharray="50.26548245743669 50.26548245743669"
                        fill="none"
                        strokeLinecap="round"
                  >
                        <animateTransform
                              attributeName="transform"
                              type="rotate"
                              dur="1s"
                              repeatCount="indefinite"
                              keyTimes="0;1"
                              values="0 50 50;360 50 50"
                        />
                  </circle>
                  <circle
                        cx={50}
                        cy={50}
                        r={23}
                        strokeWidth={7}
                        stroke="#ff0808"
                        strokeDasharray="36.12831551628262 36.12831551628262"
                        strokeDashoffset={36.12831551628262}
                        fill="none"
                        strokeLinecap="round"
                  >
                        <animateTransform
                              attributeName="transform"
                              type="rotate"
                              dur="1s"
                              repeatCount="indefinite"
                              keyTimes="0;1"
                              values="0 50 50;-360 50 50"
                        />
                  </circle>
                  <g />
            </g>
      </svg>
);
