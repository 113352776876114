import { useEffect, useState, useMemo, useContext } from "react";
import styles from "./skins.module.css";
import { GoBackButton } from "../../../components/buttons/goBack";
import { CardOne } from "../../../components/cards/cardOne";
import {
      focusOnMouseMove,
      navigationUserInputHandler,
      navigationGamepadLoop,
      playSound,
} from "../../../utilities/utilities";
import { themeContext } from "../../../context/theme";
export const Skins = ({ setShowSkins, game }) => {
      const gameModesCloseButtonClickHandler = () => {
            setShowSkins(false);
      };
      const { setTheme } = useContext(themeContext);

      const [startGamePadLoop, setStartGamePadLoop] = useState(true);
      const gamepadLoopState = useMemo(() => {
            return { run: true };
      }, []);
      const focusableElements = useMemo(() => {
            return { elements: null, index: 0 };
      }, []);

      const optionsClickHandler = (event) => {
            event.stopPropagation();
            const value = event.target.getAttribute("data-theme");
            if (value) {
                  console.log("ues");
                  game.sounds.clickSound.play();
                  setTheme(value);
                  game.userSavedData.settings.skin = value;
                  game.saveData();
            }
      };
      useEffect(() => {
            if (gamepadLoopState.run) {
                  navigationGamepadLoop(
                        game,
                        (event) => {
                              navigationUserInputHandler(
                                    event,
                                    focusableElements,
                                    game,
                                    () => {
                                          gamepadLoopState.run = false;
                                          setShowSkins(false);
                                    },
                                    playSound
                              );
                        },
                        gamepadLoopState
                  );
            }
      }, [startGamePadLoop]);

      useEffect(() => {
            focusableElements.elements =
                  document.querySelectorAll("[tabindex='3']");
      }, []);

      useEffect(() => {
            focusableElements.elements[focusableElements.index]?.focus();
      }, [gamepadLoopState.run]);

      useEffect(() => {
            return () => {
                  gamepadLoopState.run = false;
            };
      }, []);

      return (
            <CardOne
                  customTag="section"
                  attributes={{
                        className: styles["game-modes-section"],
                        onKeyDown: (event) => {
                              navigationUserInputHandler(
                                    event,
                                    focusableElements,
                                    game,
                                    () => {
                                          gamepadLoopState.run = false;
                                          setShowSkins(false);
                                    },
                                    playSound
                              );
                        },
                        onMouseMove: (event) => {
                              if (gamepadLoopState.run) {
                                    focusOnMouseMove(
                                          event,
                                          focusableElements,
                                          game,
                                          playSound
                                    );
                              }
                        },
                        tabIndex: -1,
                  }}
            >
                  <GoBackButton
                        onClickHandler={gameModesCloseButtonClickHandler}
                        game={game}
                  ></GoBackButton>
                  <div className={styles["game-modes"]}>
                        <h1 className={styles["game-modes-heading"]}>Skins</h1>
                        <div
                              className={styles["setting-container"]}
                              onClick={optionsClickHandler}
                        >
                              <div
                                    tabIndex={3}
                                    className={
                                          styles["option"] + " " + styles["red"]
                                    }
                                    data-theme="theme-0"
                              >
                                    Red
                              </div>
                              <div
                                    tabIndex={3}
                                    className={
                                          styles["option"] +
                                          " " +
                                          styles["green"]
                                    }
                                    data-theme="theme-1"
                              >
                                    Green
                              </div>
                              <div
                                    tabIndex={3}
                                    className={
                                          styles["option"] +
                                          " " +
                                          styles["violet"]
                                    }
                                    data-theme="theme-2"
                              >
                                    Violet
                              </div>
                              <div
                                    tabIndex={3}
                                    className={
                                          styles["option"] +
                                          " " +
                                          styles["yellow"]
                                    }
                                    data-theme="theme-3"
                              >
                                    Yellow
                              </div>
                              <div
                                    tabIndex={3}
                                    className={
                                          styles["option"] +
                                          " " +
                                          styles["blue"]
                                    }
                                    data-theme="theme-4"
                              >
                                    Blue
                              </div>
                        </div>
                  </div>
            </CardOne>
      );
};
