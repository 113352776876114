import { ToolTip } from "../../../components/tootip/tooltip";
import styles from "./singlePlayerStats.module.css";
import { useContext } from "react";
import { themeContext } from "../../../context/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

export const SinglePlayerStats = ({ game }) => {
      const { theme, setTheme } = useContext(themeContext);
      const stats = {
            easy: {
                  count: 0,
                  averageRating: 0,
                  perfectScoresCount: 0,
            },
            medium: {
                  count: 0,
                  averageRating: 0,
                  perfectScoresCount: 0,
            },
            hard: {
                  count: 0,
                  averageRating: 0,
                  perfectScoresCount: 0,
            },
            totalRating: 0,
      };

      const x = game.userSavedData.stats.singlePlayerMode;
      const easy = Object.values(x.easy);
      const medium = Object.values(x.medium);
      const hard = Object.values(x.hard);

      stats.easy.count = easy.length;
      stats.medium.count = medium.length;
      stats.hard.count = hard.length;

      stats.easy.averageRating =
            easy.reduce((accumulator, current, index) => {
                  if (current.done === "yes") {
                        stats.easy.perfectScoresCount++;
                        return accumulator + 100;
                  }
                  return accumulator + (current.howCloseToTarget || 0);
            }, 0) / easy.length;

      stats.medium.averageRating =
            medium.reduce((accumulator, current, index) => {
                  if (current.done === "yes") {
                        stats.medium.perfectScoresCount++;
                  }
                  return accumulator + (current.howCloseToTarget || 0);
            }, 0) / medium.length;

      stats.hard.averageRating =
            hard.reduce((accumulator, current, index) => {
                  if (current.done === "yes") {
                        stats.hard.perfectScoresCount++;
                  }
                  return accumulator + (current.howCloseToTarget || 0);
            }, 0) / hard.length;

      if (Number.isNaN(stats.easy.averageRating)) {
            stats.easy.averageRating = 0;
      }
      if (Number.isNaN(stats.medium.averageRating)) {
            stats.medium.averageRating = 0;
      }
      if (Number.isNaN(stats.hard.averageRating)) {
            stats.hard.averageRating = 0;
      }

      //   let y = 0;
      //   if (stats.easy.count) {
      //         stats.totalRating = stats.easy.averageRating;
      //         y++;
      //   }
      //   if (stats.medium.count) {
      //         stats.totalRating += stats.medium.averageRating;
      //         y++;
      //   }
      //   if (stats.hard.count) {
      //         stats.totalRating += stats.hard.averageRating;
      //         y++;
      //   }

      //   if (y !== 0) {
      //         stats.totalRating = stats.totalRating / y;
      //   }

      stats.totalRating =
            (stats.easy.averageRating * stats.easy.count +
                  stats.medium.count * stats.medium.averageRating +
                  stats.hard.count * stats.hard.averageRating) /
            (stats.easy.count + stats.medium.count + stats.hard.count);

      if (isNaN(stats.totalRating)) {
            stats.totalRating = 0;
      }
      return (
            <div className={styles["stats"]}>
                  <div
                        className={
                              styles["item-2"] + " " + styles["item-2-" + theme]
                        }
                  >
                        <div className={styles["sub-item-2"]}>
                              <div className={styles["difficulty"]}>
                                    <ToolTip text={"difficulty"}>
                                          <FontAwesomeIcon
                                                icon={faCircleInfo}
                                          ></FontAwesomeIcon>
                                    </ToolTip>
                              </div>
                              <div
                                    className={
                                          styles["filler"] +
                                          " " +
                                          styles["filler-" + theme]
                                    }
                              ></div>
                              <div className={styles["attempted-count"]}>
                                    <ToolTip text={"attempted / total"}>
                                          <FontAwesomeIcon
                                                icon={faCircleInfo}
                                          ></FontAwesomeIcon>
                                    </ToolTip>
                              </div>

                              <div
                                    className={
                                          styles["difficulty-average-rating"]
                                    }
                              >
                                    <ToolTip text={"average rating"}>
                                          <FontAwesomeIcon
                                                icon={faCircleInfo}
                                          ></FontAwesomeIcon>
                                    </ToolTip>
                              </div>
                        </div>
                        <div className={styles["sub-item-2"]}>
                              <div className={styles["difficulty"]}>Easy</div>
                              <div
                                    className={
                                          styles["filler"] +
                                          " " +
                                          styles["filler-" + theme]
                                    }
                              ></div>

                              <div className={styles["attempted-count"]}>
                                    {stats.easy.count}/9
                              </div>

                              <div
                                    className={
                                          styles["difficulty-average-rating"]
                                    }
                              >
                                    {stats.easy.averageRating.toFixed(1)}
                              </div>
                        </div>
                        <div className={styles["sub-item-2"]}>
                              <div className={styles["difficulty"]}>Medium</div>
                              <div
                                    className={
                                          styles["filler"] +
                                          " " +
                                          styles["filler-" + theme]
                                    }
                              ></div>
                              <div className={styles["attempted-count"]}>
                                    {stats.medium.count}/8
                              </div>
                              <div
                                    className={
                                          styles["difficulty-average-rating"]
                                    }
                              >
                                    {stats.medium.averageRating.toFixed(1)}
                              </div>
                        </div>
                        <div className={styles["sub-item-2"]}>
                              <div className={styles["difficulty"]}>Hard</div>
                              <div
                                    className={
                                          styles["filler"] +
                                          " " +
                                          styles["filler-" + theme]
                                    }
                              ></div>
                              <div className={styles["attempted-count"]}>
                                    {stats.hard.count}/8
                              </div>
                              <div
                                    className={
                                          styles["difficulty-average-rating"]
                                    }
                              >
                                    {stats.hard.averageRating.toFixed(1)}
                              </div>
                        </div>
                  </div>
                  <div
                        className={
                              styles["average-rating"] +
                              " " +
                              styles["average-rating-" + theme]
                        }
                  >
                        <div>Overall rating</div>
                        <div style={{ fontSize: "20px" }}>
                              {stats.totalRating.toFixed(1)}
                        </div>
                  </div>
            </div>
      );
};
