import { useRef, useMemo, useContext } from "react";
import styles from "./result.module.css";
import { useEffect } from "react";
import { CardOne } from "../../components/cards/cardOne";
import { Option } from "../../components/options/option";
import { PlayerResult } from "./playerResult";
import {
      focusOnMouseMove,
      navigationGamepadLoop,
      playSound,
} from "../../utilities/utilities";
import { CardsFlying } from "../../components/svg/cardsFlying";
import { StarsInTheSky } from "../../components/svg/stars";
import { ChallengeCompleted } from "../../components/svg/challengeCompleted";
import { ChallengeLost } from "../../components/svg/challengeLost";

import { themeContext } from "../../context/theme";

export const GameResult = ({
      game,
      setShowGameStartTimer,
      setShowGameResult,
      setShowMenuOverlay,
      setShowGame,
}) => {
      const { theme } = useContext(themeContext);
      const gamepadLoopState = useMemo(() => {
            return { run: true };
      }, []);
      const focusableElements = useMemo(() => {
            return { elements: null, index: 0 };
      }, []);
      const controllerSettingsOverlayKeyDownHandler = (event) => {
            if (event.stopPropagation) {
                  event.stopPropagation();
            }
            if (event.preventDefault) {
                  event.preventDefault();
            }
            if (event.key === "ArrowDown") {
                  if (
                        focusableElements.index ===
                        focusableElements.elements.length - 1
                  ) {
                        focusableElements.index = 0;
                  } else {
                        focusableElements.index++;
                  }
                  game.sounds.navigationSound.play();
                  focusableElements.elements[focusableElements.index].focus();
            } else if (event.key === "ArrowUp") {
                  if (focusableElements.index === 0) {
                        return;
                  } else {
                        game.sounds.navigationSound.play();
                        focusableElements.index--;
                        focusableElements.elements[
                              focusableElements.index
                        ].focus();
                  }
            } else if (event.key === "Enter") {
                  focusableElements.elements[focusableElements.index].click();
            }
      };
      const playAgainButtonClickHandler = () => {
            game.sounds.clickSound.play();
            game.reset();
            game[game.currentGameMode].challenge.initialize(game);
            gamepadLoopState.run = false;
            setShowGameStartTimer(true);
            setShowGameResult(false);
      };

      const nextChallengeClickHandler = () => {
            if (
                  game.singlePlayerMode.challengeIndex + 1 <
                  game.singlePlayerMode.challenges.length
            ) {
                  game.reset();
                  game.singlePlayerMode.challengeIndex++;
                  game.singlePlayerMode.challenge =
                        game.singlePlayerMode.challenges[
                              game.singlePlayerMode.challengeIndex
                        ];
                  game.singlePlayerMode.challenge.initialize(game);

                  gamepadLoopState.run = false;
                  setShowGameStartTimer(true);
                  setShowGameResult(false);
                  game.sounds.clickSound.play();
            }
      };

      const goToMenuClickHandler = () => {
            game.sounds.clickSound.play();
            game.reset();
            gamepadLoopState.run = false;
            setShowMenuOverlay(true);
            setShowGameResult(false);
            setShowGame(false);
      };
      useEffect(() => {
            if (gamepadLoopState.run) {
                  navigationGamepadLoop(
                        game,
                        controllerSettingsOverlayKeyDownHandler,
                        gamepadLoopState
                  );
            }
      }, []);

      useEffect(() => {
            focusableElements.elements =
                  document.querySelectorAll("[tabindex='4']");
            focusableElements.elements[0].focus();
      }, []);

      useEffect(() => {
            return () => {
                  gamepadLoopState.run = false;
            };
      }, []);

      return (
            <section
                  className={
                        styles["game-result-section"] +
                        " " +
                        styles["page-animation"]
                  }
                  onKeyDown={controllerSettingsOverlayKeyDownHandler}
            >
                  <CardsFlying></CardsFlying>

                  <div className={styles["container"]}>
                        <div className={styles["game-result-container"]}>
                              {game.currentGameMode === "singlePlayerMode" ? (
                                    <PlayerResult
                                          player={game.players[0]}
                                          index={0}
                                          game={game}
                                    ></PlayerResult>
                              ) : null}

                              {game.currentGameMode === "splitScreenMode"
                                    ? game[game.currentGameMode].winners.map(
                                            (player, index) => {
                                                  return (
                                                        <PlayerResult
                                                              player={player}
                                                              index={index}
                                                              game={game}
                                                              winner="yes"
                                                        ></PlayerResult>
                                                  );
                                            }
                                      )
                                    : null}
                              {game.currentGameMode === "splitScreenMode"
                                    ? game[game.currentGameMode].losers.map(
                                            (player, index) => {
                                                  return (
                                                        <PlayerResult
                                                              player={player}
                                                              index={
                                                                    game
                                                                          .splitScreenMode
                                                                          .winners
                                                                          .length +
                                                                    index
                                                              }
                                                              game={game}
                                                              winner="no"
                                                        ></PlayerResult>
                                                  );
                                            }
                                      )
                                    : null}
                        </div>

                        {game.currentGameMode === "singlePlayerMode" &&
                        game.singlePlayerMode.challengeIndex + 1 <
                              game.singlePlayerMode.challenges.length ? (
                              <CardOne
                                    attributes={{
                                          className:
                                                styles["next-challenge"] +
                                                " " +
                                                styles[
                                                      "next-challenge-" + theme
                                                ],
                                    }}
                              >
                                    Next Challenge:<br></br>
                                    {
                                          game.singlePlayerMode.challenges[
                                                game.singlePlayerMode
                                                      .challengeIndex + 1
                                          ].name
                                    }
                              </CardOne>
                        ) : null}

                        {game.currentGameMode === "singlePlayerMode" &&
                        game.singlePlayerMode.challengeIndex + 1 >=
                              game.singlePlayerMode.challenges.length ? (
                              <CardOne
                                    attributes={{
                                          className:
                                                styles["next-challenge"] +
                                                " " +
                                                styles[
                                                      "next-challenge-" + theme
                                                ],
                                    }}
                              >
                                    <Option
                                          customClass={
                                                styles["play-again-button"]
                                          }
                                    >
                                          End of challenges in this category
                                    </Option>
                              </CardOne>
                        ) : null}
                        <div
                              className={styles["custom-menu"]}
                              tabIndex={-1}
                              onMouseMove={(event) => {
                                    focusOnMouseMove(
                                          event,
                                          focusableElements,
                                          game,
                                          playSound
                                    );
                              }}
                        >
                              {game.currentGameMode === "splitScreenMode" ||
                              game.singlePlayerMode.challengeWon === "no" ? (
                                    <CardOne>
                                          <Option
                                                attributes={{
                                                      onClick: playAgainButtonClickHandler,
                                                      tabIndex: 4,
                                                }}
                                                customClass={
                                                      styles[
                                                            "play-again-button"
                                                      ]
                                                }
                                          >
                                                Retry
                                          </Option>
                                    </CardOne>
                              ) : null}
                              {game.currentGameMode === "singlePlayerMode" &&
                              game.singlePlayerMode.challengeIndex + 1 <
                                    game.singlePlayerMode.challenges.length ? (
                                    <CardOne>
                                          <Option
                                                attributes={{
                                                      onClick: nextChallengeClickHandler,
                                                      tabIndex: 4,
                                                }}
                                                customClass={
                                                      styles[
                                                            "play-again-button"
                                                      ]
                                                }
                                          >
                                                Next challenge
                                          </Option>
                                    </CardOne>
                              ) : null}

                              <CardOne>
                                    <Option
                                          attributes={{
                                                onClick: goToMenuClickHandler,
                                                tabIndex: 4,
                                          }}
                                          customClass={
                                                styles["play-again-button"]
                                          }
                                    >
                                          Menu
                                    </Option>
                              </CardOne>
                        </div>
                  </div>
            </section>
      );
};
