import { useMemo, useState } from "react";
import { Howl } from "howler";
import gameBackgroundSoundPath from "../../assets/sounds/backgroundSoundTwoCompressed-1.mp3";
import styles from "./loaderPage.module.css";
import { Loader } from "../../components/svg/loader";
import { GamePage } from "./gamePage";
import { CardOne } from "../../components/cards/cardOne";
import { LasersShooting } from "../../components/svg/lasersShooting";
export const LoaderPage = () => {
      const [showLoader, setShowLoader] = useState(true);
      const [showGoButton, setShowGoButton] = useState(false);
      const [showGamePage, setShowGamePage] = useState(false);

      const backgroundSoundTwo = useMemo(() => {
            return new Howl({
                  src: [gameBackgroundSoundPath],
                  onload: () => {
                        setShowLoader(false);
                        setShowGoButton(true);
                  },
            });
      }, []);

      return (
            <>
                  {!showGamePage ? (
                        <CardOne
                              customTag="div"
                              attributes={{
                                    className: styles["page"],
                              }}
                        >
                              <LasersShooting></LasersShooting>
                              <div className={styles["loader"]}>
                                    <div
                                          className={
                                                styles["logo"] +
                                                " " +
                                                styles["logo-" + "theme-0"]
                                          }
                                    >
                                          <b>
                                                M<span>-</span>tr<span>i</span>s
                                          </b>
                                    </div>
                                    <div className={styles["container"]}>
                                          {showGoButton ? (
                                                <button
                                                      className={
                                                            styles[
                                                                  "lets-go-button"
                                                            ]
                                                      }
                                                      onClick={() => {
                                                            setShowGoButton(
                                                                  false
                                                            );
                                                            setShowGamePage(
                                                                  true
                                                            );
                                                      }}
                                                >
                                                      Lets Go
                                                </button>
                                          ) : (
                                                <>
                                                      <Loader></Loader>
                                                      <div
                                                            className={
                                                                  styles[
                                                                        "loading-text"
                                                                  ]
                                                            }
                                                      >
                                                            Downloading assets
                                                      </div>
                                                </>
                                          )}
                                    </div>
                              </div>
                        </CardOne>
                  ) : null}
                  {showGamePage ? (
                        <GamePage
                              backgroundSoundTwo={backgroundSoundTwo}
                        ></GamePage>
                  ) : null}
            </>
      );
};
