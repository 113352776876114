export const Tie = () => {
      return (
            <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={61}
                  height={46}
                  preserveAspectRatio="xMidYMid"
                  style={{
                        display: "block",
                        marginBottom: "20px",
                        background: "transparent",
                  }}
            >
                  <style>
                        {
                              "@keyframes animate-qx6him7zosa{0%{animation-timing-function:cubic-bezier(.33,0,.67,0);transform:translate(0,0) rotate(0deg) scale(1,1) skew(0deg,0deg);opacity:1}48%{transform:translate(0,0) rotate(0deg);opacity:1}50%,to{animation-timing-function:cubic-bezier(.33,0,.67,0);transform:translate(0,0) rotate(0deg);opacity:0}}"
                        }
                  </style>
                  <g
                        className="ld-text"
                        style={{
                              transformOrigin: "30.5px 23px",
                              transform: "scale(1)",
                        }}
                  >
                        <g
                              style={{
                                    opacity: 1,
                                    animation:
                                          ".769231s linear -.380769s infinite normal forwards running animate-qx6him7zosa",
                                    transformBox: "view-box",
                                    transformOrigin: "-18.72px 2.49px",
                              }}
                        >
                              <path
                                    d="M5.6 16.82h4.08l2.16-11.84q5.92 0 8.8-.48l1.04-.24q-1.12 5.6-2.48 12.56h9.92l-.16 1.44h-10q-4 20.64-4 24.88 0 2.56 2.72 2.56t4.96-3.44q2.24-3.44 3.2-7.44l1.44.48q-2.96 12.64-12.24 12.64-3.84 0-6.4-2.12-2.56-2.12-2.56-5.92t3.28-21.64H5.44l.16-1.44"
                                    className="path"
                                    style={{
                                          fill: "#51cacc",
                                    }}
                                    transform="matrix(.625 0 0 .625 8 8.244)"
                              />
                        </g>
                        <g
                              style={{
                                    opacity: 1,
                                    animation:
                                          ".769231s linear -.253846s infinite normal forwards running animate-qx6him7zosa",
                                    transformBox: "view-box",
                                    transformOrigin: "2.6px 8.41px",
                              }}
                        >
                              <path
                                    d="M45.28 45.06q-2.4 2.88-6 2.88t-5.64-1.84q-2.04-1.84-2.04-4.8 0-1.04 1.28-8.72l2.64-14.32h-3.36l.16-1.44h3.44q5.92 0 8.72-.48l1.12-.24-5.52 30.24q2.4-.24 4-2.24l1.2.96"
                                    className="path"
                                    style={{
                                          fill: "#9df871",
                                    }}
                                    transform="matrix(.625 0 0 .625 8 8.244)"
                              />
                        </g>
                        <g
                              style={{
                                    opacity: 1,
                                    animation:
                                          ".769231s linear -.126923s infinite normal forwards running animate-qx6him7zosa",
                                    transformBox: "view-box",
                                    transformOrigin: "6.36px -19.31px",
                              }}
                        >
                              <path
                                    d="M38.24 8.42q-1.68-1.68-1.68-4.08 0-2.4 1.68-4.12 1.68-1.72 4.08-1.72 2.4 0 4.12 1.72 1.72 1.72 1.72 4.12 0 2.4-1.72 4.08-1.72 1.68-4.12 1.68-2.4 0-4.08-1.68"
                                    className="path"
                                    style={{
                                          fill: "#e0ff77",
                                    }}
                                    transform="matrix(.625 0 0 .625 8 8.244)"
                              />
                        </g>
                        <g
                              style={{
                                    opacity: 1,
                                    animation:
                                          ".769231s linear 0s infinite normal forwards running animate-qx6him7zosa",
                                    transformBox: "view-box",
                                    transformOrigin: "27.6px 8.41px",
                              }}
                        >
                              <path
                                    d="M67.12 16.1q10.16 0 10.16 8.08 0 5.04-4.76 7.64-4.76 2.6-13.56 2.68-.16 2.88-.16 4.56 0 3.6 1.64 5.32 1.64 1.72 4.12 1.72 2.48 0 4.52-.96 2.04-.96 3.24-2.28 1.2-1.32 2.08-2.76 1.6-2.64 1.6-3.36l1.44.64q-.24 1.28-1.44 3.4t-2.64 3.48q-1.44 1.36-4.24 2.52-2.8 1.16-6.24 1.16-6.48 0-9.8-3.08-3.32-3.08-3.32-8.44 0-9.52 4.72-14.92t12.64-5.4zm8.16 8.08q0-2.64-1.56-4.36-1.56-1.72-4.64-1.72-3.08 0-6 3.68T59.2 32.66q16.08-.08 16.08-8.48"
                                    className="path"
                                    style={{
                                          fill: "#de9dd6",
                                    }}
                                    transform="matrix(.625 0 0 .625 8 8.244)"
                              />
                        </g>
                  </g>
            </svg>
      );
};
