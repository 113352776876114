import { useState } from "react";
import touch from "../../../assets/images/touch.png";
import styles from "./touch.module.css";
import { TouchSelect } from "./touchSelect";
import { CardTwo } from "../../../components/cards/cardTwo";
import { TouchSelectOne } from "./touchSelectOne";
export const Touch = ({ playerNumber, previousGamepadLoop, game }) => {
      const [showLoader, setShowLoader] = useState(true);
      return (
            <CardTwo customClass={styles["connected-key-board"]}>
                  <div className={styles["container"]}>
                        <img
                              src={touch}
                              alt="keyboard"
                              className={styles["key-board-image"]}
                              style={{
                                    height: showLoader ? 0 : "initial",
                              }}
                              onLoad={() => {
                                    setShowLoader(false);
                              }}
                              onError={() => {
                                    setShowLoader(false);
                              }}
                        ></img>
                        {showLoader ? (
                              <div className={styles["image-loader"]}>
                                    loading...
                              </div>
                        ) : null}
                        <div>Player-0</div>
                  </div>
                  <div>
                        <TouchSelectOne
                              previousGamepadLoop={previousGamepadLoop}
                              game={game}
                        ></TouchSelectOne>
                        <TouchSelect
                              previousGamepadLoop={previousGamepadLoop}
                              bindingValue="ArrowDown"
                              game={game}
                        ></TouchSelect>
                        <TouchSelect
                              previousGamepadLoop={previousGamepadLoop}
                              bindingValue="ArrowRight"
                              game={game}
                        ></TouchSelect>
                        <TouchSelect
                              previousGamepadLoop={previousGamepadLoop}
                              bindingValue="ArrowLeft"
                              game={game}
                        ></TouchSelect>
                        <TouchSelect
                              previousGamepadLoop={previousGamepadLoop}
                              bindingValue="rotate"
                              game={game}
                        ></TouchSelect>
                        <TouchSelect
                              previousGamepadLoop={previousGamepadLoop}
                              bindingValue="lifeSaver"
                              game={game}
                        ></TouchSelect>
                        <TouchSelect
                              previousGamepadLoop={previousGamepadLoop}
                              bindingValue="hardDrop"
                              game={game}
                        ></TouchSelect>
                  </div>
            </CardTwo>
      );
};
