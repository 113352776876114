import { useEffect, useState, useMemo, useContext } from "react";
import styles from "./challenges.module.css";
import { GoBackButton } from "../../../components/buttons/goBack";
import { CardOne } from "../../../components/cards/cardOne";
import { themeContext } from "../../../context/theme";
import { Option } from "../../../components/options/option";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import {
      focusOnMouseMove,
      navigationUserInputHandler,
      navigationGamepadLoop,
      playSound,
      challenges,
} from "../../../utilities/utilities";
export const Challenges = ({
      setShowMenuOverlay,
      setShowGameStartTimer,
      setShowSplitScreen,
      game,
}) => {
      const gameModesCloseButtonClickHandler = () => {
            setShowSplitScreen(false);
      };
      const easyClickHandler = (event) => {
            event.stopPropagation();

            const value = event.target.getAttribute("data-value");
            const difficulty =
                  event.currentTarget.getAttribute("data-difficulty");
            if (!value || !difficulty) {
                  return;
            }

            game.reset();

            game.splitScreenMode.challenge =
                  challenges["splitScreen"][difficulty][value];

            game.splitScreenMode.difficulty = difficulty;

            game.splitScreenMode.challenges =
                  challenges["splitScreen"][difficulty];

            game.splitScreenMode.challengeIndex = +value;

            challenges["splitScreen"][difficulty][value].initialize(game);

            game.sounds.clickSound.play();

            setShowMenuOverlay(false);
            setShowGameStartTimer(true);
      };

      const { theme, setTheme } = useContext(themeContext);

      const [startGamePadLoop, setStartGamePadLoop] = useState(true);
      const gamepadLoopState = useMemo(() => {
            return { run: true };
      }, []);
      const focusableElements = useMemo(() => {
            return { elements: null, index: 0 };
      }, []);

      useEffect(() => {
            if (gamepadLoopState.run) {
                  navigationGamepadLoop(
                        game,
                        (event) => {
                              navigationUserInputHandler(
                                    event,
                                    focusableElements,
                                    game,
                                    () => {
                                          gamepadLoopState.run = false;
                                          setShowSplitScreen(false);
                                    },
                                    playSound
                              );
                        },
                        gamepadLoopState
                  );
            }
      }, [startGamePadLoop]);

      useEffect(() => {
            focusableElements.elements =
                  document.querySelectorAll("[tabindex='4']");
      }, []);

      useEffect(() => {
            focusableElements.elements[focusableElements.index]?.focus();
      }, [gamepadLoopState.run]);

      useEffect(() => {
            return () => {
                  gamepadLoopState.run = false;
            };
      }, []);

      return (
            <CardOne
                  customTag="section"
                  attributes={{
                        className: styles["game-modes-section"],
                        onKeyDown: (event) => {
                              navigationUserInputHandler(
                                    event,
                                    focusableElements,
                                    game,
                                    () => {
                                          gamepadLoopState.run = false;
                                          setShowSplitScreen(false);
                                    },
                                    playSound
                              );
                        },
                        onMouseMove: (event) => {
                              if (gamepadLoopState.run) {
                                    focusOnMouseMove(
                                          event,
                                          focusableElements,
                                          game,
                                          playSound
                                    );
                              }
                        },
                        tabIndex: -1,
                  }}
            >
                  <GoBackButton
                        onClickHandler={gameModesCloseButtonClickHandler}
                        game={game}
                  ></GoBackButton>
                  <div className={styles["game-modes"]}>
                        <h1 className={styles["game-modes-heading"]}>
                              Split screen (2)
                        </h1>

                        <div className={styles["setting-container"]}>
                              <div className={styles["challenges-sub-type"]}>
                                    <h2 className={styles["sub-type-heading"]}>
                                          challenges
                                    </h2>
                                    <div
                                          className={styles["challenges"]}
                                          onClick={easyClickHandler}
                                          data-difficulty="others"
                                    >
                                          {challenges["splitScreen"][
                                                "others"
                                          ].map((challenge, index) => {
                                                return (
                                                      <Option
                                                            attributes={{
                                                                  tabIndex: 4,
                                                                  "data-value":
                                                                        index,
                                                            }}
                                                            key={index}
                                                            customClass={
                                                                  styles[
                                                                        "option"
                                                                  ] +
                                                                  " " +
                                                                  styles[
                                                                        "option-" +
                                                                              theme
                                                                  ]
                                                            }
                                                      >
                                                            <div
                                                                  className={
                                                                        styles[
                                                                              "lesson-number"
                                                                        ]
                                                                  }
                                                            >
                                                                  {index + 1}.
                                                            </div>
                                                            <div
                                                                  className={
                                                                        styles[
                                                                              "lesson-name"
                                                                        ]
                                                                  }
                                                                  data-value={
                                                                        index
                                                                  }
                                                            >
                                                                  {
                                                                        challenge.name
                                                                  }
                                                            </div>
                                                            {game.userSavedData &&
                                                            game.userSavedData
                                                                  .stats
                                                                  .singlePlayerMode
                                                                  .easy[
                                                                  challenge.name
                                                            ]?.done ===
                                                                  "yes" ? (
                                                                  <FontAwesomeIcon
                                                                        icon={
                                                                              faCircleCheck
                                                                        }
                                                                  />
                                                            ) : null}
                                                      </Option>
                                                );
                                          })}
                                    </div>
                              </div>
                        </div>
                  </div>
            </CardOne>
      );
};
