import { useState } from "react";
import keyBoardImage from "../../../assets/images/160060-and-mouse-pic-black-keyboard.png";
import styles from "./keyBoard.module.css";
import { KeyBoardSelect } from "./keyBoardSelect";
import { CardTwo } from "../../../components/cards/cardTwo";
export const KeyBoard = ({ playerNumber, previousGamepadLoop, game }) => {
      const [showLoader, setShowLoader] = useState(true);
      return (
            <CardTwo customClass={styles["connected-key-board"]}>
                  <div className={styles["container"]}>
                        <img
                              src={keyBoardImage}
                              alt="keyboard"
                              className={styles["key-board-image"]}
                              style={{
                                    height: showLoader ? 0 : "initial",
                              }}
                              onLoad={() => {
                                    setShowLoader(false);
                              }}
                              onError={() => {
                                    setShowLoader(false);
                              }}
                        ></img>
                        {showLoader ? (
                              <div className={styles["image-loader"]}>
                                    loading...
                              </div>
                        ) : null}
                        <div>Player-{playerNumber}</div>
                  </div>
                  <div>
                        <KeyBoardSelect
                              previousGamepadLoop={previousGamepadLoop}
                              bindingValue="ArrowDown"
                              game={game}
                              playerNumber={playerNumber}
                        ></KeyBoardSelect>
                        <KeyBoardSelect
                              previousGamepadLoop={previousGamepadLoop}
                              bindingValue="ArrowRight"
                              playerNumber={playerNumber}
                              game={game}
                        ></KeyBoardSelect>
                        <KeyBoardSelect
                              previousGamepadLoop={previousGamepadLoop}
                              bindingValue="ArrowLeft"
                              playerNumber={playerNumber}
                              game={game}
                        ></KeyBoardSelect>
                        <KeyBoardSelect
                              previousGamepadLoop={previousGamepadLoop}
                              bindingValue="rotate"
                              playerNumber={playerNumber}
                              game={game}
                        ></KeyBoardSelect>
                        <KeyBoardSelect
                              previousGamepadLoop={previousGamepadLoop}
                              bindingValue="lifeSaver"
                              playerNumber={playerNumber}
                              game={game}
                        ></KeyBoardSelect>
                        <KeyBoardSelect
                              previousGamepadLoop={previousGamepadLoop}
                              bindingValue="hardDrop"
                              playerNumber={playerNumber}
                              game={game}
                        ></KeyBoardSelect>
                  </div>
            </CardTwo>
      );
};
