import { useState, useMemo, useEffect, useContext } from "react";
import styles from "./gamePage.module.css";
import { PlayerJsx } from "./player";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Game, saveSomeData, toastOptions } from "../../utilities/utilities";
import { Menu } from "./menu";
import { StartTimer } from "./startTimer";
import { GameResult } from "./result";
import { CardOne } from "../../components/cards/cardOne";
import { Arena } from "./arena";
import { getSavedData } from "../../utilities/utilities";
import { useAsyncValue } from "react-router";
import { defaultUserData } from "../../utilities/utilities";
import { themeContext } from "../../context/theme";
import { Analytics } from "@vercel/analytics/react";

export const GamePage = ({ backgroundSoundTwo }) => {
      const data = useAsyncValue();

      const [userSavedData, setUserSavedData] = useState(() => {
            if (
                  data.status === "success" &&
                  typeof data.payload === "object" &&
                  data.payload !== null
            ) {
                  if (data.payload.revalidated !== "true") {
                        const easy = Object.values(
                              data.payload.stats.singlePlayerMode.easy
                        );
                        const medium = Object.values(
                              data.payload.stats.singlePlayerMode.medium
                        );
                        const hard = Object.values(
                              data.payload.stats.singlePlayerMode.hard
                        );
                        saveSomeData(easy);
                        saveSomeData(medium);
                        saveSomeData(hard);
                        data.payload.revalidated = "true";
                        localStorage.setItem(
                              "userDataOne",
                              JSON.stringify(data.payload)
                        );
                  }

                  return data.payload;
            } else {
                  //   toast.error(
                  //         "something went wrong,could not load you saved data",
                  //         toastOptions
                  //   );
                  return defaultUserData;
            }
      });
      const [renderPlayersUi, setRenderPlayersUi] = useState({});
      const [renderGamePadState, setShowGamePadState] = useState({});
      const [showGameStartTimer, setShowGameStartTimer] = useState(false);
      const [showGameResult, setShowGameResult] = useState(false);
      const [showMenuOverlay, setShowMenuOverlay] = useState(true);
      const [showGame, setShowGame] = useState(false);
      const { setTheme } = useContext(themeContext);

      const game = useMemo(() => {
            return new Game(
                  setRenderPlayersUi,
                  setShowGameResult,
                  setShowMenuOverlay,
                  setShowGamePadState,
                  20,
                  10,
                  30,
                  userSavedData
            );
      }, []);

      useEffect(() => {
            setTheme(game.userSavedData.settings.skin);
      }, []);

      //   useEffect(() => {
      //         if (!game.pause && game.isGameStarted && !game.isGameOver) {
      //               game.gameLoop();
      //         }
      //   }, [renderPlayersUi, game.pause]);

      useEffect(() => {
            if (game.isGameStarted && !game.pause && !game.isGameOver) {
                  game.gameLoop();
            }
      }, [game.isGameStarted, game.pause]);

      return (
            <>
                  <Analytics></Analytics>
                  <CardOne
                        customTag="div"
                        attributes={{
                              className: styles["page"],
                        }}
                  >
                        <ToastContainer></ToastContainer>

                        <main className={styles["main"]}>
                              {showGame ? (
                                    <Arena
                                          game={game}
                                          setShowMenuOverlay={
                                                setShowMenuOverlay
                                          }
                                    ></Arena>
                              ) : (
                                    ""
                              )}
                              {showMenuOverlay ? (
                                    <Menu
                                          setShowMenuOverlay={
                                                setShowMenuOverlay
                                          }
                                          setShowGameStartTimer={
                                                setShowGameStartTimer
                                          }
                                          game={game}
                                          setShowGame={setShowGame}
                                          backgroundSoundTwo={
                                                backgroundSoundTwo
                                          }
                                    ></Menu>
                              ) : (
                                    ""
                              )}
                              {showGameStartTimer ? (
                                    <StartTimer
                                          setShowGameStartTimer={
                                                setShowGameStartTimer
                                          }
                                          game={game}
                                          setShowGame={setShowGame}
                                          setShowMenuOverlay={
                                                setShowMenuOverlay
                                          }
                                    ></StartTimer>
                              ) : (
                                    ""
                              )}
                              {showGameResult ? (
                                    <GameResult
                                          setShowGameResult={setShowGameResult}
                                          game={game}
                                          setShowGameStartTimer={
                                                setShowGameStartTimer
                                          }
                                          setShowMenuOverlay={
                                                setShowMenuOverlay
                                          }
                                          setShowGame={setShowGame}
                                    ></GameResult>
                              ) : (
                                    ""
                              )}
                        </main>
                  </CardOne>
            </>
      );
};
