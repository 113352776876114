import styles from "./arena.module.css";
import { PlayerJsx } from "./player";
import { CardOne } from "../../components/cards/cardOne";
import { GoBackButton } from "../../components/buttons/goBack";
import { InGameStats } from "./inGameStats";
import { TouchControls } from "./touchControls";
import { useRef, useCallback, useEffect, useState } from "react";

export const useDebounce = (fn, delay, immediate) => {
      const timer = useRef(null);

      const debounce = useCallback(function (...args) {
            const callNow = immediate && !timer.current;
            clearTimeout(timer.current);

            timer.current = setTimeout(() => {
                  timer.current = null;
                  if (!immediate) {
                        fn(...args);
                  }
            }, delay);

            if (callNow) {
                  fn(...args);
            }
      }, []);

      return debounce;
};

const f = (playerRef, game) => {
      if (playerRef.current) {
            let scale = 1;
            playerRef.current.style.transform = `scale(${scale})`;
            let layout = playerRef.current.getBoundingClientRect();

            while (
                  layout.width > window.innerWidth - 40 ||
                  layout.height > window.innerHeight - 70
            ) {
                  scale = scale - 0.01;
                  playerRef.current.style.transform = `scale(${scale})`;
                  layout = playerRef.current.getBoundingClientRect();
            }
      }
};

export const Arena = ({ game, setShowMenuOverlay }) => {
      const [timer, setTimer] = useState(0);
      const playerRef = useRef(null);
      const debounced = useDebounce(f, 0);
      let className = styles["arena-overlay"];

      if (game.currentGameMode === "splitScreenMode") {
            className = className + " " + styles["arena-overlay-split-screen"];
      }
      const goBackHandler = (event) => {
            if (event.stopPropagation) {
                  event.stopPropagation();
            }

            if (game.isGameStarted) {
                  setShowMenuOverlay((previous) => {
                        return !previous;
                  });
                  game.pause = !game.pause;
            }
      };

      useEffect(() => {
            f(playerRef, game);
      }, [game.showTouchControls]);

      useEffect(() => {
            const f1 = () => {
                  debounced(playerRef, game);
            };
            window.addEventListener("resize", f1);
            return () => {
                  window.removeEventListener("resize", f1);
            };
      }, []);

      useEffect(() => {
            if (game.isGameStarted && !game.isGameOver) {
                  const timerId = setInterval(() => {
                        if (game.isGameOver) {
                              clearInterval(timerId);
                        } else if (!game.pause) {
                              game.time++;
                              game.players.forEach((player) => {
                                    if (!player.isGameOver) {
                                          player.time++;
                                    }
                              });

                              game.fixedFrameRate.currentFps =
                                    game.fixedFrameRate.framesCounter;
                              game.fixedFrameRate.framesCounter = 0;

                              game.renderPlayersUi({});
                        } else if (game.pause) {
                              game.fixedFrameRate.currentFps = 0;
                              game.fixedFrameRate.framesCounter = 0;
                        }
                  }, 1000);
                  return () => {
                        clearInterval(timerId);
                        // setTimer(0);
                  };
            }
      }, [game.isGameStarted]);

      useEffect(() => {
            const gameArenaOutOfFocusHandler = (event) => {
                  if (game.isGameStarted && !game.isGameOver) {
                        game.pause = true;
                        setShowMenuOverlay(true);
                  }
            };

            window.addEventListener("blur", gameArenaOutOfFocusHandler);
            return () => {
                  window.removeEventListener(
                        "blur",
                        gameArenaOutOfFocusHandler
                  );
            };
      }, []);

      useEffect(() => {
            const gameArenaOnKeyDownHandler = (event) => {
                  if (event.key === "Escape") {
                        if (game.isGameStarted) {
                              setShowMenuOverlay(true);
                              game.pause = true;
                        }
                  }
            };
            window.addEventListener("keydown", gameArenaOnKeyDownHandler);
            return () => {
                  window.removeEventListener(
                        "keydown",
                        gameArenaOnKeyDownHandler
                  );
            };
      }, []);

      //   useEffect(() => {
      //         game.sounds.gameBackgroundSound.volume(0.05);
      //   }, []);
      return (
            <CardOne
                  customTag="section"
                  attributes={{
                        className,
                  }}
            >
                  <GoBackButton
                        onClickHandler={goBackHandler}
                        game={game}
                  ></GoBackButton>
                  <InGameStats game={game}></InGameStats>

                  {game.currentGameMode === "singlePlayerMode" ? (
                        <>
                              <div
                                    className={
                                          game.showTouchControls === "yes"
                                                ? styles[
                                                        "single-player-container"
                                                  ] +
                                                  " " +
                                                  styles["touch-controls-on"]
                                                : styles[
                                                        "single-player-container"
                                                  ]
                                    }
                                    ref={playerRef}
                              >
                                    <PlayerJsx
                                          player={game.players[0]}
                                          game={game}
                                    ></PlayerJsx>
                                    {game.showTouchControls === "yes" ? (
                                          <TouchControls
                                                game={game}
                                                player={game.players[0]}
                                          ></TouchControls>
                                    ) : null}
                              </div>
                        </>
                  ) : null}

                  {game.currentGameMode === "splitScreenMode" ? (
                        <div
                              className={styles["split-screen-container"]}
                              ref={playerRef}
                        >
                              {game.players.map((player) => {
                                    return (
                                          <PlayerJsx
                                                player={player}
                                                game={game}
                                                key={"player " + player.number}
                                          ></PlayerJsx>
                                    );
                              })}
                        </div>
                  ) : null}
            </CardOne>
      );
};
