import styles from "./options.module.css";
import { useEffect, useMemo, useContext } from "react";
import { themeContext } from "../../context/theme";
import {
      focusOnMouseMove,
      navigationUserInputHandler,
      navigationGamepadLoop,
      playSound,
} from "../../utilities/utilities";
import { GoBackButton } from "../buttons/goBack";
export const Options = ({
      game,
      setShowOptions,
      previousGamepadLoop,
      clickHandler,
      attributes,
      children,
      showBottom,
}) => {
      const focusableElements = useMemo(() => {
            return { elements: null, index: 0 };
      }, []);
      const currentGamePadLoopState = useMemo(() => {
            return {
                  run: true,
            };
      }, []);
      const { theme } = useContext(themeContext);
      const goBackCallBack = () => {
            currentGamePadLoopState.run = false;
            setShowOptions(false);
            previousGamepadLoop.gamepadLoopState.run = true;
            previousGamepadLoop.setStartGamePadLoop(true);
      };

      useEffect(() => {
            navigationGamepadLoop(
                  game,
                  (event) => {
                        navigationUserInputHandler(
                              event,
                              focusableElements,
                              game,
                              goBackCallBack,
                              playSound
                        );
                  },
                  currentGamePadLoopState
            );
            previousGamepadLoop.gamepadLoopState.run = false;
            previousGamepadLoop.setStartGamePadLoop(false);
      }, []);
      useEffect(() => {
            focusableElements.elements =
                  document.querySelectorAll("[tabindex='3']");
            focusableElements.elements[focusableElements.index]?.focus();
      });

      useEffect(() => {
            return () => {
                  currentGamePadLoopState.run = false;
            };
      }, []);

      let className = styles["options"] + " " + styles["options-" + theme];
      if (!showBottom) {
            className = className + " " + styles["show-top"];
      }

      return (
            <div
                  className={className}
                  onClick={(event) => {
                        clickHandler(event, currentGamePadLoopState);
                  }}
                  {...attributes}
                  onKeyDown={(event) => {
                        navigationUserInputHandler(
                              event,
                              focusableElements,
                              game,
                              goBackCallBack,
                              playSound
                        );
                  }}
                  onMouseMove={(event) => {
                        if (currentGamePadLoopState.run) {
                              focusOnMouseMove(
                                    event,
                                    focusableElements,
                                    game,
                                    playSound
                              );
                        }
                  }}
            >
                  <GoBackButton
                        game={game}
                        customClass={styles["go-back-button"]}
                        onClickHandler={(event) => {
                              if (event.stopPropagation) {
                                    event.stopPropagation();
                              }
                              navigationUserInputHandler(
                                    { key: "Back" },
                                    focusableElements,
                                    game,
                                    goBackCallBack,
                                    playSound
                              );
                        }}
                  ></GoBackButton>
                  {children}
            </div>
      );
};
