import styles from "./controlSettings.module.css";
import { GoBackButton } from "../../components/buttons/goBack";
import { useEffect, useState, useMemo } from "react";
import { Controller } from "./controller";
import { CardOne } from "../../components/cards/cardOne";
import { KeyBoard } from "./keyBoard/keyBoard";
import { Touch } from "./touch/touch";
import {
      focusOnMouseMove,
      navigationUserInputHandler,
      navigationGamepadLoop,
      playSound,
} from "../../utilities/utilities";

export const ControlSettings = ({ setShowSettingsOverLay, game }) => {
      const [startGamePadLoop, setStartGamePadLoop] = useState(true);
      const gamepadLoopState = useMemo(() => {
            return { run: true };
      }, []);
      const focusableElements = useMemo(() => {
            return { elements: null, index: 0 };
      }, []);

      useEffect(() => {
            if (gamepadLoopState.run) {
                  navigationGamepadLoop(
                        game,
                        (event) => {
                              navigationUserInputHandler(
                                    event,
                                    focusableElements,
                                    game,
                                    () => {
                                          gamepadLoopState.run = false;
                                          setShowSettingsOverLay(false);
                                    },
                                    playSound
                              );
                        },
                        gamepadLoopState
                  );
            }
      }, [startGamePadLoop]);

      useEffect(() => {
            focusableElements.elements =
                  document.querySelectorAll("[tabindex='2']");
            focusableElements.index = 0;
      }, [game.connectedJoysticks]);

      useEffect(() => {
            if (gamepadLoopState.run) {
                  focusableElements.elements[focusableElements.index]?.focus();
            }
      }, [gamepadLoopState.run, game.connectedJoysticks]);

      useEffect(() => {
            return () => {
                  gamepadLoopState.run = false;
            };
      }, []);

      const settingsOverlayCloseButtonClickHandler = () => {
            setShowSettingsOverLay(false);
      };

      return (
            <CardOne
                  customTag="section"
                  attributes={{
                        className: styles["settings-overlay"],
                        onKeyDown: (event) => {
                              navigationUserInputHandler(
                                    event,
                                    focusableElements,
                                    game,
                                    () => {
                                          gamepadLoopState.run = false;
                                          setShowSettingsOverLay(false);
                                    },
                                    playSound
                              );
                        },
                        onMouseMove: (event) => {
                              if (gamepadLoopState.run) {
                                    focusOnMouseMove(
                                          event,
                                          focusableElements,
                                          game,
                                          playSound
                                    );
                              }
                        },
                        tabIndex: -1,
                  }}
            >
                  <GoBackButton
                        onClickHandler={settingsOverlayCloseButtonClickHandler}
                        game={game}
                  ></GoBackButton>
                  <div className={styles["settings"]}>
                        <h1 className={styles["controller-settings-heading"]}>
                              Controls
                        </h1>
                        <div className={styles["connected-controllers"]}>
                              {/* {game.players.map((player, index) => {
                                    const joystick = game.joysticks[index];
                                    if (joystick) {
                                          return (
                                                <Controller
                                                      key={index}
                                                      joystickIndex={index}
                                                      previousGamepadLoop={{
                                                            gamepadLoopState,
                                                            setStartGamePadLoop,
                                                      }}
                                                      joystick={joystick}
                                                      game={game}
                                                ></Controller>
                                          );
                                    }
                              })} */}

                              <Controller
                                    joystickIndex={0}
                                    previousGamepadLoop={{
                                          gamepadLoopState,
                                          setStartGamePadLoop,
                                    }}
                                    joystick={game.joysticks[0]}
                                    game={game}
                              ></Controller>
                              <Controller
                                    joystickIndex={1}
                                    previousGamepadLoop={{
                                          gamepadLoopState,
                                          setStartGamePadLoop,
                                    }}
                                    joystick={game.joysticks[1]}
                                    game={game}
                              ></Controller>
                              {[0, 0].map((player, index) => {
                                    return (
                                          <KeyBoard
                                                previousGamepadLoop={{
                                                      gamepadLoopState,
                                                      setStartGamePadLoop,
                                                }}
                                                playerNumber={index + ""}
                                                game={game}
                                                key={index}
                                          ></KeyBoard>
                                    );
                              })}
                              <Touch
                                    previousGamepadLoop={{
                                          gamepadLoopState,
                                          setStartGamePadLoop,
                                    }}
                                    playerNumber={0}
                                    game={game}
                              ></Touch>
                        </div>
                  </div>
            </CardOne>
      );
};
