import { useEffect, useRef, useState } from "react";
import styles from "./donutProgress.module.css";

export const DonutProgress = ({ challengeStats }) => {
      const setRenderDonut = useState(true)[1];
      const donutAnimationState = useRef({
            run: true,
            value: 0,
      });
      let degree = challengeStats?.howCloseToTarget;
      if (challengeStats && challengeStats.done === "yes") {
            degree = 100;
      } else if (
            challengeStats?.done &&
            challengeStats?.howCloseToTarget === undefined
      ) {
            degree = 0;
      }

      useEffect(() => {
            const animation = () => {
                  donutAnimationState.current.value += 1;

                  if (donutAnimationState.current.value > degree) {
                        donutAnimationState.current.value = degree;
                        return;
                  }
                  if (!donutAnimationState?.current?.run) {
                        return;
                  }
                  setRenderDonut((previous) => {
                        return !previous;
                  });
                  requestAnimationFrame(animation);
            };
            animation();
            return () => {
                  if (donutAnimationState?.current?.run) {
                        donutAnimationState.current.run = false;
                  }
            };
      }, []);
      return (
            <>
                  {degree || degree === 0 ? (
                        <div className={styles["donut"]}>
                              <div
                                    className={styles["box"]}
                                    style={{
                                          background: `conic-gradient(white ${Math.round(
                                                (donutAnimationState.current
                                                      .value /
                                                      100) *
                                                      360
                                          )}deg, transparent -360deg)`,
                                    }}
                              ></div>
                              <div className={styles["inner-box"]}>
                                    <span>{degree}</span>
                              </div>
                        </div>
                  ) : null}
            </>
      );
};
