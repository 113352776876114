export const InGameStats = ({ game }) => {
      const style = {
            position: "absolute",
            top: "10px",
            color: "white",
            right: "5px",
            fontSize: "13px",
      };
      return (
            <div style={style}>
                  <div>
                        <b style={{ fontSize: "14px" }}>
                              {game.fixedFrameRate.currentFps}
                        </b>{" "}
                        fps
                  </div>
                  {/* <div>
                        frame time:{" "}
                        {game.fixedFrameRate.currentFps === 0
                              ? 0
                              : (1000 / game.fixedFrameRate.currentFps).toFixed(
                                      1
                                )}{" "}
                        ms
                  </div> */}
                  {/* <div> average fps: {game.fixedFrameRate.averageFps} </div> */}
            </div>
      );
};
