import { CardThree } from "../../../components/cards/cardThree";
import styles from "./touchSelect.module.css";
import { useState } from "react";
import { Options } from "../../../components/options/options";
import { toast } from "react-toastify";
import { playSound, toastOptions } from "../../../utilities/utilities";
import { Option } from "../../../components/options/option";

export const TouchSelect = ({
      previousGamepadLoop,
      bindingValue,
      playerNumber,
      game,
}) => {
      const [showOptions, setShowOptions] = useState(false);
      const [showBottom, setShowBottom] = useState(true);
      const settingClickHandler = (event) => {
            event.stopPropagation();
            game.sounds.clickSound.play();
            const positions = event.currentTarget.getBoundingClientRect();
            if (positions.top < window.innerHeight / 2) {
                  setShowBottom(true);
            } else {
                  setShowBottom(false);
            }
            setShowOptions(true);
      };

      let assignedButtonName = null;

      for (let i = 0; i < game.touch.defaultKeyBindings.length; i++) {
            if (game.touch.defaultKeyBindings[i] === bindingValue) {
                  assignedButtonName = game.touch.buttonsNames[i];
            }
      }

      let temporary = bindingValue;
      if (temporary === "ArrowDown") {
            temporary = "down";
      } else if (temporary === "ArrowLeft") {
            temporary = "left";
      } else if (temporary === "ArrowRight") {
            temporary = "right";
      }

      const optionsClickHandler = (event, currentGamePadLoopState) => {
            event.stopPropagation();
            const buttonIndex = event.target.getAttribute("data-button-index");

            const bindingValue =
                  event.currentTarget.getAttribute("data-binding-value");

            if (game.touch.updateGameKeyBinding(buttonIndex, bindingValue)) {
                  game.userSavedData.settings.controls.touch =
                        game.touch.defaultKeyBindings;
                  game.saveData();
                  playSound(game, "click");
                  setShowOptions(false);
                  currentGamePadLoopState.run = false;
                  previousGamepadLoop.gamepadLoopState.run = true;
                  previousGamepadLoop.setStartGamePadLoop(true);
                  toast.success("updated scuccesfully", toastOptions);
            } else {
                  toast.error("already used", toastOptions);
            }
      };

      return (
            <div className={styles["setting-container"]}>
                  <span>{temporary}</span>
                  <CardThree
                        customClass={styles["setting"]}
                        tabIndex={2}
                        onClick={settingClickHandler}
                  >
                        {assignedButtonName}

                        {showOptions ? (
                              <Options
                                    previousGamepadLoop={previousGamepadLoop}
                                    game={game}
                                    setShowOptions={setShowOptions}
                                    attributes={{
                                          "data-binding-value": bindingValue,
                                    }}
                                    clickHandler={optionsClickHandler}
                                    showBottom={showBottom}
                              >
                                    <Option
                                          attributes={{
                                                tabIndex: 3,
                                                "data-button-index": "12",
                                          }}
                                    >
                                          dpad up
                                    </Option>

                                    <Option
                                          attributes={{
                                                tabIndex: 3,
                                                "data-button-index": "13",
                                          }}
                                    >
                                          dpad down
                                    </Option>
                                    <Option
                                          attributes={{
                                                tabIndex: 3,
                                                "data-button-index": "14",
                                          }}
                                    >
                                          dpad left
                                    </Option>

                                    <Option
                                          attributes={{
                                                tabIndex: 3,
                                                "data-button-index": "15",
                                          }}
                                    >
                                          dpad right
                                    </Option>

                                    <Option
                                          attributes={{
                                                tabIndex: 3,
                                                "data-button-index": "2",
                                          }}
                                    >
                                          x
                                    </Option>
                                    <Option
                                          attributes={{
                                                tabIndex: 3,
                                                "data-button-index": "0",
                                          }}
                                    >
                                          a
                                    </Option>
                                    <Option
                                          attributes={{
                                                tabIndex: 3,
                                                "data-button-index": "1",
                                          }}
                                    >
                                          b
                                    </Option>
                                    <Option
                                          attributes={{
                                                tabIndex: 3,
                                                "data-button-index": "3",
                                          }}
                                    >
                                          y
                                    </Option>
                              </Options>
                        ) : null}
                  </CardThree>
            </div>
      );
};
