import { useContext, useEffect, useMemo, useState } from "react";
import { ControlSettings } from "./controlSettings";
import { GameModes } from "./gameModes/gameModes";
import { themeContext } from "../../context/theme";
import { CardOne } from "../../components/cards/cardOne";
import { Skins } from "./skins/skins";
import { Guide } from "./guide/guide";
import { LasersShooting } from "../../components/svg/lasersShooting";
import { Challenges } from "./singlePlayer/challenges";
import { Challenges as SplitScreenChallenges } from "./splitScreen/challenges";
import styles from "./menu.module.css";
import {
      focusOnMouseMove,
      navigationUserInputHandler,
      playSound,
} from "../../utilities/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
      faFingerprint,
      faGamepad,
      faKeyboard,
      faMouse,
} from "@fortawesome/free-solid-svg-icons";

export const Menu = ({
      setShowMenuOverlay,
      game,
      setShowGameStartTimer,
      setShowGame,
      backgroundSoundTwo,
}) => {
      const [showSettingsOverLay, setShowSettingsOverLay] = useState(false);
      const [showSinglePlayer, setShowSinglePlayer] = useState(false);
      const [showSplitScreen, setShowSplitScreen] = useState(false);
      const [showGameModes, setShowGameModes] = useState(false);
      const [showSkins, setShowSkins] = useState(false);
      const [showGuide, setShowGuide] = useState(false);
      const [renderMenu, setRenderMenu] = useState({});
      const { theme } = useContext(themeContext);

      const focusableElements = useMemo(() => {
            return { elements: null, index: 0 };
      }, [game.isGameStarted, game.pause]);

      const newGameButtonClickHandler = (event) => {
            game.sounds.clickSound.play();
            game.reset();
            setShowMenuOverlay(false);
            setShowGameStartTimer(true);
      };

      const controlsClickHandler = async () => {
            game.sounds.clickSound.play();
            setShowSettingsOverLay(true);
      };

      const gameModesClickHandler = () => {
            game.sounds.clickSound.play();
            setShowGameModes(true);
      };

      const resumeGameClickHandler = () => {
            game.sounds.clickSound.play();
            if (game.isGameStarted) {
                  setShowMenuOverlay(false);
                  game.pause = false;
            }
      };

      const skinsClickHandler = () => {
            game.sounds.clickSound.play();
            setShowSkins(true);
      };

      const guideClickHandler = () => {
            game.sounds.clickSound.play();
            setShowGuide(true);
      };
      const quitGameClickHandler = () => {
            game.sounds.clickSound.play();
            game.reset();
            setRenderMenu({});
            setShowGame(false);
      };

      const singlePlayerClickHandler = () => {
            game.currentGameMode = "singlePlayerMode";
            game.createPlayers(1);
            game.sounds.clickSound.play();
            setShowSinglePlayer(true);
      };

      const splitScreenClickHandler = () => {
            game.currentGameMode = "splitScreenMode";
            game.sounds.clickSound.play();
            game.createPlayers(2);
            setShowSplitScreen(true);
      };

      useEffect(() => {
            focusableElements.elements =
                  document.querySelectorAll("[tabindex='0']");
            focusableElements.elements[0].focus();
      }, [game.isGameStarted]);

      useEffect(() => {
            if (
                  !showSettingsOverLay &&
                  !showGameModes &&
                  !showSkins &&
                  !showGuide &&
                  !showSinglePlayer &&
                  !showSplitScreen
            ) {
                  focusableElements.elements[focusableElements.index]?.focus();
            }
      }, [
            showSettingsOverLay,
            showGameModes,
            showSkins,
            showGuide,
            showSinglePlayer,
            showSplitScreen,
      ]);

      const gamepadLoop = () => {
            let joystick = null;
            game.joysticks.forEach((element) => {
                  if (joystick === null && element !== null) {
                        joystick = element;
                  }
            });

            if (joystick) {
                  if (joystick.throttleCount < 10) {
                        joystick.throttleCount++;
                  }
                  const gamepads = navigator.getGamepads();
                  const gamepad = gamepads[joystick.gamepad.index];
                  if (gamepad) {
                        gamepad.buttons.forEach((button, buttonIndex) => {
                              if (button.pressed) {
                                    if (
                                          joystick.previousPressedButtonIndex !==
                                          buttonIndex
                                    ) {
                                          navigationUserInputHandler(
                                                {
                                                      key: joystick
                                                            .navigationKeyBindings[
                                                            buttonIndex
                                                      ],
                                                },
                                                focusableElements,
                                                game,
                                                null,
                                                playSound
                                          );
                                          joystick.previousPressedButtonIndex =
                                                buttonIndex;
                                          joystick.throttleCount = 0;
                                    } else if (joystick.throttleCount === 10) {
                                          navigationUserInputHandler(
                                                {
                                                      key: joystick
                                                            .navigationKeyBindings[
                                                            buttonIndex
                                                      ],
                                                },
                                                focusableElements,
                                                game,
                                                null,
                                                playSound
                                          );
                                          joystick.throttleCount = 0;
                                    }
                              }
                        });
                  }
            }

            setRenderMenu({});
      };
      useEffect(() => {
            if (
                  !showSettingsOverLay &&
                  !showGameModes &&
                  !showSkins &&
                  !showGuide &&
                  !showSinglePlayer &&
                  !showSplitScreen
            ) {
                  requestAnimationFrame(gamepadLoop);
            }
      }, [
            showSettingsOverLay,
            renderMenu,
            showGameModes,
            showSkins,
            showGuide,
            showSinglePlayer,
            showSplitScreen,
      ]);

      useEffect(() => {
            backgroundSoundTwo.loop(true);
            backgroundSoundTwo.volume(0.8);
            backgroundSoundTwo.play();
            return () => {
                  backgroundSoundTwo.stop();
            };
      }, []);

      return (
            <>
                  <CardOne
                        customTag="section"
                        attributes={{
                              className: styles["menu-overlay-section"],
                              onKeyDown: (event) => {
                                    if (
                                          !showSettingsOverLay &&
                                          !showGameModes &&
                                          !showSkins &&
                                          !showGuide &&
                                          !showSinglePlayer &&
                                          !showSplitScreen
                                    ) {
                                          navigationUserInputHandler(
                                                event,
                                                focusableElements,
                                                game,
                                                null,
                                                playSound
                                          );
                                    }
                              },
                              onMouseMove: (event) => {
                                    if (
                                          !showSettingsOverLay &&
                                          !showGameModes &&
                                          !showSkins &&
                                          !showGuide &&
                                          !showSinglePlayer &&
                                          !showSplitScreen
                                    ) {
                                          focusOnMouseMove(
                                                event,
                                                focusableElements,
                                                game,
                                                playSound
                                          );
                                    }
                              },
                              tabIndex: -1,
                        }}
                  >
                        <LasersShooting></LasersShooting>
                        <div
                              className={
                                    styles["logo"] +
                                    " " +
                                    styles["logo-" + theme]
                              }
                        >
                              <b>
                                    M<span>-</span>tr<span>i</span>s
                              </b>
                        </div>

                        <div className={styles["container"]}>
                              {!game.isGameStarted ? (
                                    <>
                                          <div
                                                className={
                                                      styles["option"] +
                                                      " " +
                                                      styles["option-" + theme]
                                                }
                                                tabIndex={0}
                                                onClick={
                                                      singlePlayerClickHandler
                                                }
                                          >
                                                SINGLE PLAYER
                                          </div>
                                          <div
                                                className={
                                                      styles["option"] +
                                                      " " +
                                                      styles["option-" + theme]
                                                }
                                                tabIndex={0}
                                                onClick={
                                                      splitScreenClickHandler
                                                }
                                          >
                                                SPLIT SCREEN
                                          </div>
                                    </>
                              ) : null}

                              {/* {!game.isGameStarted ? (
                              <div
                                    className={
                                          styles["option"] +
                                          " " +
                                          styles["option-" + theme]
                                    }
                                    tabIndex={0}
                                    onClick={newGameButtonClickHandler}
                              >
                                    NEW GAME
                              </div>
                        ) : null} */}

                              {game.isGameStarted && game.pause ? (
                                    <>
                                          <CardOne
                                                attributes={{
                                                      className:
                                                            styles[
                                                                  "challenge-details"
                                                            ] +
                                                            " " +
                                                            styles[
                                                                  "challenge-details-" +
                                                                        theme
                                                            ],
                                                }}
                                          >
                                                {
                                                      game[game.currentGameMode]
                                                            .challenge.name
                                                }
                                          </CardOne>
                                          <div
                                                className={
                                                      styles["option"] +
                                                      " " +
                                                      styles["option-" + theme]
                                                }
                                                tabIndex={0}
                                                onClick={resumeGameClickHandler}
                                          >
                                                RESUME GAME
                                          </div>
                                    </>
                              ) : null}

                              <div
                                    className={
                                          styles["option"] +
                                          " " +
                                          styles["option-" + theme]
                                    }
                                    onClick={controlsClickHandler}
                                    tabIndex={0}
                              >
                                    CONTROLS
                              </div>

                              {!game.isGameStarted && !game.pause ? null : (
                                    <div
                                          className={
                                                styles["option"] +
                                                " " +
                                                styles["option-" + theme]
                                          }
                                          onClick={quitGameClickHandler}
                                          tabIndex={0}
                                    >
                                          QUIT GAME
                                    </div>
                              )}

                              <div
                                    className={
                                          styles["option"] +
                                          " " +
                                          styles["option-" + theme]
                                    }
                                    onClick={skinsClickHandler}
                                    tabIndex={0}
                              >
                                    SKINS
                              </div>
                              <div
                                    className={
                                          styles["option"] +
                                          " " +
                                          styles["option-" + theme]
                                    }
                                    onClick={guideClickHandler}
                                    tabIndex={0}
                              >
                                    GUIDE
                              </div>

                              <div className={styles["controls-icons"]}>
                                    <FontAwesomeIcon
                                          icon={faGamepad}
                                    ></FontAwesomeIcon>
                                    <FontAwesomeIcon
                                          icon={faMouse}
                                    ></FontAwesomeIcon>
                                    <FontAwesomeIcon
                                          icon={faKeyboard}
                                    ></FontAwesomeIcon>
                                    <FontAwesomeIcon
                                          icon={faFingerprint}
                                    ></FontAwesomeIcon>
                              </div>
                        </div>
                  </CardOne>
                  {showSettingsOverLay ? (
                        <ControlSettings
                              setShowSettingsOverLay={setShowSettingsOverLay}
                              game={game}
                        ></ControlSettings>
                  ) : (
                        ""
                  )}

                  {showGameModes ? (
                        <GameModes
                              setShowGameModes={setShowGameModes}
                              game={game}
                        ></GameModes>
                  ) : null}
                  {showSkins ? (
                        <Skins setShowSkins={setShowSkins} game={game}></Skins>
                  ) : null}

                  {showGuide ? (
                        <Guide setShowGuide={setShowGuide} game={game}></Guide>
                  ) : null}

                  {showSinglePlayer ? (
                        <Challenges
                              game={game}
                              setShowMenuOverlay={setShowMenuOverlay}
                              setShowGameStartTimer={setShowGameStartTimer}
                              setShowSinglePlayer={setShowSinglePlayer}
                        ></Challenges>
                  ) : null}
                  {showSplitScreen ? (
                        <SplitScreenChallenges
                              game={game}
                              setShowMenuOverlay={setShowMenuOverlay}
                              setShowGameStartTimer={setShowGameStartTimer}
                              setShowSplitScreen={setShowSplitScreen}
                        ></SplitScreenChallenges>
                  ) : null}
            </>
      );
};
