export const YouWon = () => (
      <svg
            xmlns="http://www.w3.org/2000/svg"
            width={163}
            height={43}
            preserveAspectRatio="xMidYMid"
            style={{
                  display: "block",
                  marginBottom: "20px",
                  background: "transparent",
            }}
      >
            <style>
                  {
                        "@keyframes blink-485a7557-3560-4220-9f43-43f27bbb140f{0%,49.75%,to{opacity:1}50.25%,99.5%{opacity:0}}"
                  }
            </style>
            <g
                  style={{
                        transformOrigin: "81.5px 21.5px",
                        transform: "scale(.55)",
                  }}
            >
                  <g
                        style={{
                              opacity: 1,
                              transformOrigin: "-85.96px .320797px",
                              animation:
                                    ".757576s linear -.416667s infinite normal forwards running blink-485a7557-3560-4220-9f43-43f27bbb140f",
                        }}
                  >
                        <path
                              fill="#51cacc"
                              strokeWidth="none"
                              d="M34.28-9.92q1.8-5.6 3.28-12.4 1.48-6.8 1.48-12.48 0-5.68-2.64-8.96-2.64-3.28-7.36-3.28-6.8 0-12.56 5.12-2.88 2.56-4.6 6.84-1.72 4.28-1.72 8.92t1.32 7.76q1.32 3.12 3.16 4.72 3.92 3.44 6.8 4.08l1.36.24-.56 1.52q-1.36-.4-2.28-.68-.92-.28-3.24-1.64-2.32-1.36-3.96-3.08-1.64-1.72-2.96-4.96t-1.32-7.24q0-10.32 5.96-16.8t15.48-6.48q17.6 0 17.6 16.72 0 8.48-1.44 12.8Q66-62.32 81.68-62.32q4.48 0 6.32 1.96 1.84 1.96 1.84 5.8H88q-.4-3.52-1.88-4.68-1.48-1.16-4.44-1.16-4.96 0-10.76 5.24T60-41.76Q49.28-24.88 43.12-6.88q-4.96 14.32-6.96 20.32H25.44q1.6-3.52 4.32-10.64 2.72-7.12 4.52-12.72"
                              className="path"
                              style={{
                                    fill: "#51cacc",
                              }}
                              transform="translate(-53.62 46.26)"
                        />
                  </g>
                  <g
                        style={{
                              opacity: 1,
                              transformOrigin: "-59.52px 9.56079px",
                              animation:
                                    ".757576s linear -.333333s infinite normal forwards running blink-485a7557-3560-4220-9f43-43f27bbb140f",
                        }}
                  >
                        <path
                              fill="#51cacc"
                              strokeWidth="none"
                              d="M72.48.72q-6.16 0-9.36-3.08-3.2-3.08-3.2-7.76t.84-8q.84-3.32 2.76-6.4 1.92-3.08 5.8-4.84 3.88-1.76 9.68-1.76t9.04 3.08q3.24 3.08 3.24 8.44 0 9.12-4.84 14.72Q81.6.72 72.48.72zm-3.52-8.24q0 6.4 3.72 6.4t6.64-6.44q2.92-6.44 2.92-15.32 0-6.72-3.64-6.72t-6.64 6.12q-3 6.12-3 15.96"
                              className="path"
                              style={{
                                    fill: "#9df871",
                              }}
                              transform="translate(-53.62 46.26)"
                        />
                  </g>
                  <g
                        style={{
                              opacity: 1,
                              transformOrigin: "-22.28px 9.56079px",
                              animation:
                                    ".757576s linear -.25s infinite normal forwards running blink-485a7557-3560-4220-9f43-43f27bbb140f",
                        }}
                  >
                        <path
                              fill="#51cacc"
                              strokeWidth="none"
                              d="M100.16-29.52q-1.92 0-4 2.24l-1.2-.96q.56-1.12 2.48-2 1.92-.88 4.24-.88 7.28 0 7.28 6.56 0 2.8-1.44 10t-1.44 9.44q0 3.44 3.28 3.44 2.32 0 4.52-2.72 2.2-2.72 3.8-7.52l3.36-18.48q5.92 0 8.64-.48l1.04-.24-5.36 30.24q2.4-.24 4-2.24l1.2.96q-2.4 2.88-6 2.88t-5.6-1.76q-2-1.76-2.08-4.96-3.36 6.72-9.92 6.72-4.72 0-7.36-2.68-2.64-2.68-2.64-7.4 0-2.48 1.52-9.56t1.68-10.6"
                              className="path"
                              style={{
                                    fill: "#e0ff77",
                              }}
                              transform="translate(-53.62 46.26)"
                        />
                  </g>
                  <g
                        style={{
                              opacity: 1,
                              transformOrigin: "42.48px 9.56079px",
                              animation:
                                    ".757576s linear -.166667s infinite normal forwards running blink-485a7557-3560-4220-9f43-43f27bbb140f",
                        }}
                  >
                        <path
                              fill="#51cacc"
                              strokeWidth="none"
                              d="M192.88-21.2q-1.68-1.68-1.68-4.08 0-2.4 1.68-4.12 1.68-1.72 3.8-1.72t3.6 1.08q1.48 1.08 2.12 2.76.08.08.08.16v.08q.96 2.08.96 5.72 0 3.64-.48 6.2t-1.76 5.44q-1.28 2.88-3.12 5.16-1.84 2.28-4.92 3.76T186.32.72q-6.4 0-9.6-4.64Q172.88.72 166.24.72q-5.12 0-8.36-3.2-3.24-3.2-3.24-7.36t1.48-10.28q1.48-6.12 1.64-9.4-1.28.08-2.48.92-1.2.84-1.76 1.64l-.56.8-1.2-.96q.24-.48.72-1.12.48-.64 2.44-1.76t4.92-1.12q2.96 0 4.76 1.92 1.8 1.92 1.8 4.88t-1.6 9.16q-1.6 6.2-1.6 8.72 0 2.52 1.16 3.96t3.32 1.44q4.64 0 7.92-4.8-.88-2.24-.88-5.2t1.48-9.08q1.48-6.12 1.64-9.4-1.28.08-2.48.92-1.2.84-1.76 1.64l-.56.8-1.2-.96q.24-.48.72-1.12.48-.64 2.44-1.76t4.92-1.12q2.96 0 4.76 1.92 1.8 1.92 1.8 4.88t-1.6 9.16q-1.6 6.2-1.6 8.72 0 2.52 1.16 3.96t3.32 1.44q5.36 0 9.6-5.8 4.24-5.8 4.24-13.08 0-.56-.16-1.68-1.68 2.08-4.28 2.08-2.6 0-4.28-1.68"
                              className="path"
                              style={{
                                    fill: "#de9dd6",
                              }}
                              transform="translate(-53.62 46.26)"
                        />
                  </g>
                  <g
                        style={{
                              opacity: 1,
                              transformOrigin: "87.84px 9.56079px",
                              animation:
                                    ".757576s linear -.0833333s infinite normal forwards running blink-485a7557-3560-4220-9f43-43f27bbb140f",
                        }}
                  >
                        <path
                              fill="#51cacc"
                              strokeWidth="none"
                              d="M219.84.72q-6.16 0-9.36-3.08-3.2-3.08-3.2-7.76t.84-8q.84-3.32 2.76-6.4 1.92-3.08 5.8-4.84 3.88-1.76 9.68-1.76t9.04 3.08q3.24 3.08 3.24 8.44 0 9.12-4.84 14.72-4.84 5.6-13.96 5.6zm-3.52-8.24q0 6.4 3.72 6.4t6.64-6.44q2.92-6.44 2.92-15.32 0-6.72-3.64-6.72t-6.64 6.12q-3 6.12-3 15.96"
                              className="path"
                              style={{
                                    fill: "#ff708e",
                              }}
                              transform="translate(-53.62 46.26)"
                        />
                  </g>
                  <g
                        style={{
                              opacity: 1,
                              transformOrigin: "125.64px 9.56079px",
                              animation:
                                    ".757576s linear 0s infinite normal forwards running blink-485a7557-3560-4220-9f43-43f27bbb140f",
                        }}
                  >
                        <path
                              fill="#51cacc"
                              strokeWidth="none"
                              d="M273.52-.88q1.92 0 4-2.24l1.2.96q-.56 1.12-2.48 2-1.92.88-4.24.88-7.28 0-7.28-6.56 0-2.8 1.44-10t1.44-9.44q0-3.44-3.28-3.44-2.64 0-5.08 3.44t-4.04 9.44L252.48-.88q2.48-.24 4.08-2.24l1.2.96q-2.4 2.88-6 2.88t-5.64-1.84q-2.04-1.84-2.04-4.8 0-1.12 1.28-8.8l2.64-14.8q-2.4.24-4 2.24l-1.2-.96q2.4-2.88 6.04-2.88t5.64 1.84q2 1.84 2 5.2v.48q3.28-7.52 10.24-7.52 4.72 0 7.36 2.68 2.64 2.68 2.64 7.4 0 2.48-1.52 9.56t-1.68 10.6"
                              className="path"
                              style={{
                                    fill: "#51cacc",
                              }}
                              transform="translate(-53.62 46.26)"
                        />
                  </g>
            </g>
      </svg>
);
