import { createContext, Context, useState, useEffect } from "react";
import { getSavedData } from "../utilities/utilities";
export const themeContext = createContext("red");

export const ThemeProvider = ({ children }) => {
      const [theme, setTheme] = useState(() => {
            return "theme-0";
      });

      //   useEffect(() => {
      //         (async () => {
      //               const savedData = await getSavedData();
      //               if (savedData) {
      //                     console.log(savedData);
      //                     setTheme(savedData.settings.skin);
      //               }
      //         })();
      //   }, []);

      //   useEffect(() => {
      //         async function saveTheme() {
      //               const savedData = await JSON.parse(
      //                     localStorage.getItem("userDataOne")
      //               );
      //               if (savedData) {
      //                     savedData.settings.skin = theme;
      //                     localStorage.setItem(
      //                           "userDataOne",
      //                           JSON.stringify(savedData)
      //                     );
      //               }
      //         }

      //         saveTheme();
      //   }, [theme]);

      return (
            <themeContext.Provider value={{ theme, setTheme }}>
                  {children}
            </themeContext.Provider>
      );
};
